<script>
    import { ngaConfig } from '../../stores'
    
    let mapBannerImage = null

    $: {
      mapBannerImage = $ngaConfig && $ngaConfig['all'].mapBannerImage
    }
</script>

<section>
  <h2 class="ma4 f3">EXPLORE BY MAP</h2>
  <hr />
  <div class="flex mb4">
    <a class="w-100 dib" href="/explore/map" title="Go to Map">
      <img
        class="w-100"
        src="{mapBannerImage}"
        alt="World map"
      />
    </a>
  </div>
</section>

<style>
  a {
    max-height: 600px;
  }

  img {
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
</style>
