<script>
  import { onMount } from 'svelte'
  import { locationsNodes } from '../components/maps/stores.js'
  import { ngaConfig } from '../stores'
  import Locations2dMap from '../components/maps/Locations2dMap.svelte'
  import CurriculumSection from '../components/maps/CurriculumSection.svelte'
  import LocationsInterestingData from '../components/maps/LocationsInterestingData.svelte';

  onMount(async () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    await locationsNodes.load()
  })

  let mapDisclaimer = ''

  $:{
    mapDisclaimer = $ngaConfig && $ngaConfig['all'].mapDisclaimer
  }


  let viewOnMap;

  function viewOnMapRequest(event) {
    let tgn = event.detail.text;
    viewOnMap(tgn);
  }

</script>

<div class="flex flex-column">
  <h1 class="mh4 mt0 f2 ff-nga-cond">Map</h1>
    
  <div class="mh4 flex flex-column">
    <p class="f5-ns pb0 ma0">{mapDisclaimer}</p>
  </div>
  {#if $locationsNodes}
    <LocationsInterestingData data={$locationsNodes} on:viewOnMap={viewOnMapRequest}/>
    <Locations2dMap data={$locationsNodes}  bind:viewOnMap={viewOnMap} />
  {:else}
    <p>loading locations data</p>
  {/if}
  <CurriculumSection />
</div>
