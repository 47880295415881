<script>
  import SearchTag from '../tags/SearchTag.svelte'
  import { filter, ngaConfig } from '../../stores'
  import { getNGAImageUrl } from '../../utils'

  let heroUrl = ''
  let heroCaption = ''
  let artworkData = null
  let explanationText = ''

  $: {
    if ($ngaConfig && $filter) {
      const prop = $filter === 'learn' ? 'edu' : 'all'

      const { heroImageId } = $ngaConfig[prop]
      artworkData = $ngaConfig.artworks[heroImageId]
      const tb = JSON.parse(artworkData.defaultThumbnail)
      heroUrl = getNGAImageUrl(tb.imgpreview)
      explanationText = $ngaConfig[prop].explanationText
      heroCaption = $ngaConfig[prop].heroImageCaption
    }
  }
</script>

<section class="flex flex-column flex-row-l mh4 mb4">
  <div class="flex-grow-1 w-100 w-50-l">
    <h1 class="lh-solid mt0 mb4 f1 mr4">Learn with art</h1>
    <div class="flex flex-row">
      <div class="search-tag-container w-100 mb4">
        <SearchTag />
      </div>
    </div>
    <div class="flex flex-row">
      <p>{explanationText}</p>
    </div>
  </div>
  <div class="w-100 w-50-l flex flex-column items-end-l">
    <div class="dib">
      <a
        href="https://searchthecollection.nga.gov.au/object?uniqueId={artworkData?.uniqueId}"
        target="_blank"
      >
        <img class="" src={heroUrl} alt="Beautiful artwork for a red human." />
      </a>
      {#if artworkData}
        <p class="img-caption">{heroCaption}</p>
      {/if}
    </div>
  </div>
</section>

<style>
  h1 {
    font-family: 'nga-cond';
    max-width: 600px;
  }

  img {
    background-color: rgb(200, 200, 200);
    margin-bottom: 1rem;
    max-height: 600px;
    max-height: 600px;
  }

  .img-caption {
    font-size: 1rem;
    margin: 0.25rem 0 0;
  }

  .search-tag-container {
    max-width: 400px;
  }
</style>
