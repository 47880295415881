<script>
  export let data
  export let tag

  const getCount = (d) => Object.values(d).reduce((p, c) => p + c, 0)

  let tagCount = 0

  const countTags = (label, countProp, searchField) => {
    const obj = data.count[countProp]

    if (obj !== undefined) {
      const count = getCount(obj)

      tagCount += count
    }
  }

  $: {
    if (data) {
      // reset count before adding buttons to increment the total count
      tagCount = 0

      countTags('Subjects/Concepts', 'subjectTerm', 'subjectTerTerm')
      countTags(
        'Periods and Art Movements',
        'stylePeriodTerm',
        'stylePeriodTerTerm',
      )
      countTags('Curriculum Terms', 'eduCurriculumTerm', 'eduCurriculumTerTerm')
    }
  }
</script>

<h1 class="title f2 f1-ns mh4 mv0 pa0 lh-solid">{tag}</h1>
<div class="flex flex-column flex-row-l mh4 mv4">
  <div class="w-100">
    <p class="mt0 mb4">
      {tagCount} work{#if tagCount > 1}s{/if} of art in the NGA Collection are tagged
      {tag}.
    </p>
    <p class="f7 w-50-l pb4 ma0">
      Contains information from the Getty Art & Architecture Thesaurus® [AAT]
      and Getty Thesaurus of Geographic Names® [TGN] which is made available
      under ODC Attribution License. Also contains thesauri content sourced from
      School Online Thesaurus [ScOT]; Australian Pictorial Thesaurus [APT]; NGA
      Subjects Thesaurus [NGA]; NGA Place Thesaurus [NGA] and NGA Curriculum
      Thesaurus [NGA].
    </p>
  </div>
</div>
<hr />

<style>
  .title {
    font-family: 'nga-cond';
  }

  hr {
    margin-bottom: 2rem;
  }
</style>
