export const dictToArray = (dict, sort = 'asc') => {
  const arr = []
  for (const [key, value] of Object.entries(dict)) {
    arr.push({ key, value })
  }

  const sortReturn = sort === 'asc' ? -1 : 1

  arr.sort((a, b) => {
    if (a.value < b.value) return sortReturn
    else if (a.value > b.value) return sortReturn * -1
    return 0
  })

  return arr
}

export const vocabObjectCountToString = (obj) => {
  let str = ''
  let i = 0
  for (let vocab in obj) {
    if (i > 0) str += ' - '
    str += `[${vocab}] (${obj[vocab]})`
    // str += `[${vocab.toUpperCase()}] (${obj[vocab]})`
    i++
  }

  return str
}

export const vocabCombinedCount = (obj) => {
  let count = 0

  for (let vocab in obj) {
    count += Number(obj[vocab])
  }

  return count
}

export const vocabObjectToString = (obj) => {
  let str = ''
  let i = 0
  for (let vocab in obj) {
    if (i > 0) str += ' - '
    str += `(${vocab})`
    i++
  }

  return str
}

/*
data:
{
  [source]: {
    [vocab]: number
  }
}
*/
export const getVocabCountString = (data) => {
  const items = {}

  // aggregate sources
  for (let source in data) {
    for (let vocab in data[source]) {
      const count = data[source][vocab]

      if (items[vocab] === undefined) {
        items[vocab] = 0
      }

      items[vocab] += count
    }
  }
  return vocabObjectCountToString(items)
}

export const getNGAImageUrl = (path) =>
  `${STC_URL}/stcapi/service/ngacd/asset/preview?contentInfo=${path}`

export const prepareArtworkData = (doc) => {
  const data = {
    url: null,
    title: null,
    artistName: null,
    year: null,
    img: null,
    onView: false,
  }

  if (doc.defaultThumbnail !== undefined) {
    try {
      const tb = JSON.parse(doc.defaultThumbnail)
      data.img = getNGAImageUrl(tb.imgpreview)
    } catch (e) {}
  }

  if (doc.uniqueId !== undefined) {
    data.url = `${STC_URL}/object?uniqueId=${doc.uniqueId}`
  }

  if (doc.creators !== undefined) {
    if (doc.creators.length > 0) {
      data.artistName = doc.creators[0]
    }
  }

  if (doc.vraDateDisplayText !== undefined) {
    data.year = doc.vraDateDisplayText
  }

  if (doc.vraTitleDisplayText !== undefined) {
    data.title = doc.vraTitleDisplayText
  }

  if (doc.woaOnDisplay !== undefined) {
    data.onView = doc.woaOnDisplay
  }

  return data
}

const createFilterItem = (field, value, label = null) => {
  const o = { field, value, label }
  if (label === null) {
    o.label = value
  }
  return o
}

/**
 * Create absolute url with search results
 *
 * @param {String} keyword - Search keyword
 * @param {Array} filters - Array of objects: { field: "term", value: "", label: "" }
 * @returns String
 */
export const getSearchTheCollectionUrl = (keyword = '*', filters = []) => {
  const encodedFilters = encodeURIComponent(JSON.stringify(filters))
  return `${STC_URL}/results?selectedFilters=${encodedFilters}&keyword=${keyword}&includeParts=false`
}

/**
 * Create absolute url with search results for a specific term and tag
 *
 * @param {String} tag - Tag value for example
 * @param {String} term - eduCurriculumTerTerm / subjectTerTerm / stylePeriodTerTerm
 * @returns String
 */
export const getSearchTheCollectionTagCountUrl = (tag, term) => {
  const filters = [createFilterItem(term, tag)]
  return getSearchTheCollectionUrl('*', filters)
}

/**
 * Create absolute url with search results for a specific term and tag
 *
 * @param {String} tag - Tag value for example
 * @param {Number} startYear -
 * @param {Number} endYear -
 * @param {Array} terms - array with at least one of those values eduCurriculumTerTerm / subjectTerTerm / stylePeriodTerTerm
 * @returns String
 */
export const getSearchTheCollectionTagYearGapUrl = (
  tag,
  startYear,
  endYear,
  terms = [],
) => {
  const filters = []

  terms.forEach((term) => filters.push(createFilterItem(term, tag)))

  filters.push(
    createFilterItem(
      'date',
      {
        from: startYear,
        to: endYear,
      },
      `${startYear}-${endYear}`,
    ),
  )

  return getSearchTheCollectionUrl('*', filters)
}

/**
 * Create absolute url with search results for a specific term and tag and place
 *
 * @param {String} tag - Tag value for example
 * @param {String} place - like Sydney
 * @param {Array} terms - array with at least one of those values eduCurriculumTerTerm / subjectTerTerm / stylePeriodTerTerm
 * @returns String
 */
export const getSearchTheCollectionTagPlaceUrl = (tag, place, terms = [], id) => {
  const filters = []
 
  terms.forEach((term) => filters.push(createFilterItem(term, tag)))

  filters.push({ 'field':'placeHierarchyId','value': id, 'label': place })

  return getSearchTheCollectionUrl('*', filters)
}

/**
 * Create absolute url with search results for a specific term and tag and object type
 *
 * @param {String} tag - Tag value for example
 * @param {String} objectType - like prints
 * @param {Array} terms - array with at least one of those values eduCurriculumTerTerm / subjectTerTerm / stylePeriodTerTerm
 * @returns String
 */
export const getSearchTheCollectionTagObjectTypeUrl = (
  tag,
  objectType,
  terms = [],
) => {
  const filters = []

  terms.forEach((term) => filters.push(createFilterItem(term, tag)))

  filters.push(createFilterItem('objectType', objectType))

  return getSearchTheCollectionUrl('*', filters)
}

const TAG_COUNT_TERM_DICT = {
  subjectTerm: 'subjectTerTerm',
  stylePeriodTerm: 'stylePeriodTerTerm',
  eduCurriculumTerm: 'eduCurriculumTerTerm',
}

/**
 * Get all terms available inside the data.count object inside a tag json file
 *
 * @param {Object} count
 * @returns Array of strings - Possible values in TAG_COUNT_TERM_DICT
 */
export const getTermsFromTagCount = (count) => {
  // const terms =
  return Object.keys(count).map((key) => TAG_COUNT_TERM_DICT[key])
}

export const scrollToElement = (element, offsetY = 0) => {
  const r = element.getBoundingClientRect()
  window.scroll({
    top: r.top + window.scrollY + offsetY,
    behavior: 'smooth',
  })
}

// object structure from tag on tags-count.json
export const getTagObjTotalCount = (tagObj) => {
  const vocabs = []
  let count = 0

  if (tagObj === undefined) return count

  for (let source in tagObj) {
    for (let vocab in tagObj[source]) {

      count += Number(tagObj[source][vocab])
    }
  }

  return count
}