class MapBoxCustomControl {
  constructor({ className = "", title = '', eventHandler = evtHndlr}) {
    this._className = className
    this._title = title
    this._eventHandler = eventHandler
  }
  onAdd(map){
    this._map = map;
    this._span = document.createElement('span')
    this._span.className = 'mapboxgl-ctrl-icon'
    // this._span.title = this._title
    // this._span.textContent = this._title

    this._btn = document.createElement('button')
    this._btn.className = `${this._className}`
    this._btn.type = 'button'
    this._btn.onclick = this._eventHandler
    this._btn.appendChild(this._span)

    this._container = document.createElement('div')
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group'
    this._container.appendChild(this._btn)

    return this._container
  }
  onRemove(){
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  }
}

export default MapBoxCustomControl
