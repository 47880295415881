<script>
  import { navigate } from 'svelte-routing'
  import { filter, filteredTags } from '../../stores.js'

  const MAX_RESULTS = 10

  let searchInput = ''
  let keys = []
  let tags = {}
  let result = []
  let totalResults = 0

  const strToTag = () => {
    return searchInput.toLowerCase().replaceAll('-', ' ')
  }

  const reset = () => {
    searchInput = ''
    result = []
  }

  const getVocabsAndCounts = (key) => {
    const vocabs = []
    const res = {
      count: '0 Artwork',
      vocab: '',
    }
    let count = 0

    if (tags[key] === undefined) return res

    const obj = tags[key]

    for (let source in obj) {
      for (let vocab in obj[source]) {
        if (!vocabs.includes(vocab)) {
          // avoid vocab duplication
          vocabs.push(vocab)
          res.vocab += ` (${vocab})`
        }
        count += Number(obj[source][vocab])
      }
    }

    res.count = `${count} Artwork${count > 1 ? 's' : ''}`

    return res
  }

  const mapResultItems = (key) => {
    const { vocab, count } = getVocabsAndCounts(key)
    return {
      key,
      vocab,
      count,
    }
  }

  const search = async () => {
    if (searchInput.length > 0) {
      const str = strToTag(searchInput)
      const found = keys.filter((value) => value.toLowerCase().includes(str))
      totalResults = found.length
      result = found.slice(0, MAX_RESULTS).map(mapResultItems)
    } else {
      reset()
    }
  }

  const setTag = (value) => {
    navigate(`/explore/${$filter}/view/${value}`)
    reset()
  }

  $: {
    if ($filteredTags !== null) {
      tags = $filteredTags
      keys = Object.keys(tags)
    }
  }

  const onPointerUp = (e) => {
    console.log(e)
    // check if the click was outide this component
    let found = e.path.filter((n) => {
      return n.classList && n.classList.contains('search-tag-comp')
    })

    if (searchInput.length > 0 && found.length === 0) {
      // close the result list
      reset()
    }
  }
</script>

<svelte:window on:pointerup={onPointerUp} />
<div class="search-tag-comp w-100">
  <div class="panel relative">
    <div class="flex flex-row search">
      <input
        placeholder="SEARCH A NEW TAG"
        class="flex-grow-1 f6 normal"
        bind:value={searchInput}
        on:keyup={search}
      /><button class="search-bt" value="Search" on:click={search}
        ><svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="path-1-outside-1_394:2720"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="22"
            height="22"
            fill="black"
          >
            <rect fill="white" width="22" height="22" />
            <path
              d="M20.75 21C20.686 21 20.6221 20.9756 20.5732 20.9268L15.3035 15.657C13.7831 17.0911 11.7364 17.9729 9.48645 17.9729C4.80701 17.9729 1 14.1659 1 9.48645C1 4.80701 4.80701 1 9.48645 1C14.1659 1 17.9729 4.80701 17.9729 9.48645C17.9729 11.7364 17.0911 13.7831 15.657 15.3035L20.9268 20.5732C21.0244 20.6709 21.0244 20.8291 20.9268 20.9268C20.8779 20.9756 20.814 21 20.75 21ZM9.48645 1.5C5.08276 1.5 1.5 5.08276 1.5 9.48645C1.5 13.8901 5.08276 17.4729 9.48645 17.4729C13.8901 17.4729 17.4729 13.8901 17.4729 9.48645C17.4729 5.08276 13.8901 1.5 9.48645 1.5Z"
            />
          </mask>
          <path
            d="M20.75 21C20.686 21 20.6221 20.9756 20.5732 20.9268L15.3035 15.657C13.7831 17.0911 11.7364 17.9729 9.48645 17.9729C4.80701 17.9729 1 14.1659 1 9.48645C1 4.80701 4.80701 1 9.48645 1C14.1659 1 17.9729 4.80701 17.9729 9.48645C17.9729 11.7364 17.0911 13.7831 15.657 15.3035L20.9268 20.5732C21.0244 20.6709 21.0244 20.8291 20.9268 20.9268C20.8779 20.9756 20.814 21 20.75 21ZM9.48645 1.5C5.08276 1.5 1.5 5.08276 1.5 9.48645C1.5 13.8901 5.08276 17.4729 9.48645 17.4729C13.8901 17.4729 17.4729 13.8901 17.4729 9.48645C17.4729 5.08276 13.8901 1.5 9.48645 1.5Z"
            fill="black"
          />
          <path
            d="M20.5732 20.9268L20.9268 20.5732H20.9268L20.5732 20.9268ZM15.3035 15.657L14.9604 15.2933L15.3137 14.9601L15.6571 15.3035L15.3035 15.657ZM9.48645 17.9729V17.4729V17.9729ZM15.657 15.3035L15.3035 15.6571L14.9601 15.3137L15.2933 14.9604L15.657 15.3035ZM20.9268 20.9268L20.5732 20.5732H20.5732L20.9268 20.9268ZM20.75 21.5C20.5587 21.5 20.366 21.4266 20.2197 21.2803L20.9268 20.5732C20.8782 20.5246 20.8133 20.5 20.75 20.5V21.5ZM20.2197 21.2803L14.9499 16.0106L15.6571 15.3035L20.9268 20.5732L20.2197 21.2803ZM15.6466 16.0207C14.0377 17.5383 11.8698 18.4729 9.48645 18.4729V17.4729C11.6031 17.4729 13.5286 16.6439 14.9604 15.2933L15.6466 16.0207ZM9.48645 18.4729C4.53086 18.4729 0.5 14.442 0.5 9.48645H1.5C1.5 13.8898 5.08315 17.4729 9.48645 17.4729V18.4729ZM0.5 9.48645C0.5 4.53086 4.53086 0.5 9.48645 0.5V1.5C5.08315 1.5 1.5 5.08315 1.5 9.48645H0.5ZM9.48645 0.5C14.442 0.5 18.4729 4.53086 18.4729 9.48645H17.4729C17.4729 5.08315 13.8898 1.5 9.48645 1.5V0.5ZM18.4729 9.48645C18.4729 11.8698 17.5383 14.0377 16.0207 15.6466L15.2933 14.9604C16.6439 13.5286 17.4729 11.6031 17.4729 9.48645H18.4729ZM16.0106 14.9499L21.2803 20.2197L20.5732 20.9268L15.3035 15.6571L16.0106 14.9499ZM21.2803 20.2197C21.5732 20.5126 21.5732 20.9874 21.2803 21.2803L20.5732 20.5732C20.4756 20.6708 20.4756 20.8292 20.5732 20.9268L21.2803 20.2197ZM21.2803 21.2803C21.134 21.4266 20.9413 21.5 20.75 21.5V20.5C20.6867 20.5 20.6218 20.5246 20.5732 20.5732L21.2803 21.2803ZM9.48645 2C5.35891 2 2 5.35891 2 9.48645H1C1 4.80662 4.80662 1 9.48645 1V2ZM2 9.48645C2 13.614 5.35891 16.9729 9.48645 16.9729V17.9729C4.80662 17.9729 1 14.1663 1 9.48645H2ZM9.48645 16.9729C13.614 16.9729 16.9729 13.614 16.9729 9.48645H17.9729C17.9729 14.1663 14.1663 17.9729 9.48645 17.9729V16.9729ZM16.9729 9.48645C16.9729 5.35891 13.614 2 9.48645 2V1C14.1663 1 17.9729 4.80662 17.9729 9.48645H16.9729Z"
            fill="black"
            mask="url(#path-1-outside-1_394:2720)"
          />
        </svg>
      </button>
    </div>
    {#if result.length > 0}
      <div class="result-list bg-transparent w-100 absolute">
        {#each result as item, i}
          <button class="item flex w-100 f6 items-center" on:click={() => setTag(item.key)}>
            <span class="b">{item.key}</span>
            <span class=" flex-grow-1 ml2">{item.vocab}</span>
            <span class="count i flex-shrink-0">{item.count}</span>
          </button>
        {/each}
      </div>
    {/if}
  </div>
</div>

<style>
  .item {
    cursor: pointer;
    /* padding: 0.15rem 0; */
    /* font-size: 0.75rem; */
    text-transform: capitalize;
    border: 1px solid #000;
    border-radius: 5px;
    border-top: 0;
  }

  .item:hover,
  .item:focus {
    background: #000;
    color: #fff;
  }

  button,
  input {
    border: 0;
    background-color: #fff;
    margin: 0;
    padding: 0 1rem;
    height: 40px;
    text-align: left;
  }

  input {
    border: 1px solid black;
    border-radius: 5px;
    padding: 0 0.5rem;
  }

  .search-bt {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    height: 100%;
    margin: 0;
    padding: 0;
    width: 40px;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
  }

  svg {
    vertical-align: middle;
  }

  button:hover {
    background-color: transparent;
  }
</style>
