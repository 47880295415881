<script>
  import {
    max,
    scaleBand,
    scaleTime,
    stack,
    stackOffsetNone,
    stackOrderNone,
  } from 'd3'
  import { onDestroy, onMount } from 'svelte'
  import { scrollToElement } from '../../../utils'

  /*
    References:
    - https://observablehq.com/@d3/stacked-horizontal-bar-chart
  */

  export let data = null

  const MAX_ITEMS = 10
  let viewAllActive = false
  let viewButtonVisible = false

  const BAR_HEIGHT = 40

  let el
  let width = 800
  let height = 500
  let xAxis

  const COLORS = ['#EDF8B1', '#C7E9B4', '#7FCDBB', '#41B6C4', '#1D91C0']

  let stackData = []
  let deptosKeys = []
  let yDomain = []
  let x
  let y

  const resizeOb = new ResizeObserver((entries) => {
    let rect = entries[0].contentRect
    // usdate width to resize svg
    width = rect.width
    // recalculate items too
    update()
  })

  onMount(() => resizeOb.observe(el))

  onDestroy(() => {
    resizeOb.unobserve(el)
    resizeOb.disconnect()
  })

  const update = () => {
    const { ot } = data
    const d3Data = []
    let keys = Object.keys(ot)

    // sort keys by count will affect d3 stack sorting.
    keys.sort((k1, k2) => {
      const a = ot[k1].c
      const b = ot[k2].c
      if (a > b) return -1
      if (a < b) return 1
      return 0
    })

    viewButtonVisible = keys.length > MAX_ITEMS

    if (!viewAllActive) {
      keys = keys.slice(0, MAX_ITEMS)
    }

    yDomain = keys

    const maxCount = max(keys, (key) => ot[key].c)

    height = keys.length * BAR_HEIGHT

    deptosKeys = []

    // get all departments
    for (let k in ot) {
      const deptos = Object.keys(ot[k].d)
      deptos.forEach((depto) => {
        if (!deptosKeys.includes(depto)) {
          deptosKeys.push(depto)
        }
      })
    }

    deptosKeys.sort()

    // go through of visible keys only
    keys.forEach(name => {
      const item = ot[name]
      const o = { name, total: item.c, url: item.url }

      // make sure all records have all deptosKeys!!!
      deptosKeys.forEach((depto) => {
        o[depto] = item.d[depto] !== undefined ? item.d[depto] : 0
      })

      d3Data.push(o)
    })

    const stackP = stack()
      .keys(deptosKeys)
      .order(stackOrderNone)
      .offset(stackOffsetNone)

    stackData = stackP(d3Data)

    x = scaleTime()
      .domain([0, max(stackData, (d) => max(d, (d) => d[1]))])
      .range([0, width])

    y = scaleBand().domain(yDomain).range([0, height]).padding(0)

    // define x domain with counts
    xAxis = [0]

    const incx = maxCount / 7

    for (let i = 1; i < 6; i++) {
      xAxis[i] = Math.round(incx * i)
    }

    xAxis.push(maxCount)
  }

  const toggleView = () => {
    viewAllActive = !viewAllActive

    if (!viewAllActive) {
      scrollToElement(el, -200)
    }

    update()
  }

  $: {
    if (data !== null) {
      update()
    } else {
      stackData = []
    }
  }
</script>

<div bind:this={el}>
  <div class="flex flex-row justify-between w-100 mb2">
    {#each xAxis as x}
      <span class="fxs moon-gray">{x}</span>
    {/each}
  </div>
  <svg viewBox="0 0 {width} {height}" {height}>
    <g>
      {#each stackData as sd}
        <g fill={COLORS[sd.index]}>
          {#each sd as item}
            <rect
              fill="inherit"
              class="rect-link"
              y={y(item.data.name)}
              x={x(item[0])}
              width={x(item[1]) - x(item[0])}
              height={BAR_HEIGHT}
            />
          {/each}
        </g>
      {/each}
    </g>

    <g transform="translate(0, 0)">
      {#each yDomain as item, index}
        <g transform="translate(0, {index * 40})" font-size="14">
          <a xlink:href={data.ot[item].url} target="blank">
            <rect class="rect-link" width="100%" height="40" x="0" y="0" fill="transparent"></rect>
            <text class="f6" fill="black" y="24" x={width - 4} text-anchor="end"
            >{item} ({data.ot[item].c})</text
          ></a>
        </g>
      {/each}
    </g>

    <g transform="translate(0, 0)">
      {#each yDomain as item, index}
        <line
          y1={index * 40}
          x1="0"
          y2={index * 40}
          x2={width}
          stroke-width="1"
          stroke="rgba(0,0,0,0.2)"
        />
      {/each}
    </g>
    <line
      x1="0"
      y1={height}
      x2={width}
      y2={height}
      stroke-width="1"
      stroke="rgba(0,0,0,0.2)"
    />
  </svg>
  {#if viewButtonVisible}
    <div class="flex justify-center mt2">
      <span class="view-bt ph3 pv2 underline link" on:click={toggleView}
        >View {#if viewAllActive}Less -{:else}All +{/if}</span
      >
    </div>
  {/if}
  <div class="flex flex-column flex-row-l w-100 mt4-l">
    {#each deptosKeys as depto, i}
      <div
        class="depto-item fxs w-100 w-20-l mt2 mt0-l ml4-l ttu tc ph4 lh-copy"
        style="background-color: {COLORS[i]}"
      >
        {depto}
      </div>
    {/each}
  </div>
</div>

<style>
  svg {
    width: 100%;
  }

  .depto-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
  }

  .view-bt {
    border: 0;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    font-size: 1.4rem;
  }

  .view-bt:hover {
    color: rgba(0, 0, 0, 1);
  }

  .rect-link {
    stroke-width: 1;
    stroke: transparent;
    transition: all 0.3s ease-in-out;
  }

  .rect-link:hover {
    stroke-width: 1;
    stroke: black;
    fill: white;
    fill-opacity: 0.1;
  }

  @media screen and (min-width: 60em) {
    .depto-item:first-child {
      margin-left: 0;
    }
  }
</style>
