<script>
  export let data = {
    img: 'https://via.placeholder.com/960x540',
    title: 'Artwork Title',
    artistName: 'Artist Name',
    year: 2001,
    url: '${STC_URL}/object?searchIn=artistOrCulture&searchIn=title&searchIn=medium&uniqueId=32440',
    onView: false,
  }
</script>

<a
  class="w-100 w-50-m w-20-l pa4 link artwork-card flex flex-column justify-center"
  href={data.url}
  title="View Artwork"
  target="_blank"
>
  {#if data.img}<img class="mb2 artwork-img" src={data.img} alt="Item" />{/if}
  <div class="flex flex-row">
    <div class="flex-grow-1">
      {#if data.artistName}<p class="f6 b ma0">{data.artistName}</p>{/if}
      {#if data.title}<p class="f7 ma0 lh-title">{data.title}</p>{/if}
      {#if data.year}<p class="f7 ma0 lh-title">{data.year}</p>{/if}
    </div>
    {#if data.onView}
      <div class="flex-shirink-0">
        <span class="f7">On view</span>
      </div>
    {/if}
  </div>
</a>

<style>
  a {
    transition: background 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  a:hover {
    text-decoration: none;
    background: rgba(0, 0, 0, 0.05);
  }

  span {
    font-weight: 700;
    background-color: #828282;
    border-radius: 68.7305px;
    color: #fff;
    padding: 0.4rem 0.8rem;
  }

  .artwork-card {
    max-width: 400px;
  }

  .artwork-img {
    max-height: 250px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
