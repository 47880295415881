<script>
  import ArtworkItem from '../common/ArtworkItem.svelte'
  import { filter, ngaConfig } from '../../stores'
  import { prepareArtworkData } from '../../utils'

  let list = []

  $: {
    if ($ngaConfig && $filter) {
      const prop = $filter === 'learn' ? 'edu' : 'all'
      const { artworksIds } = $ngaConfig[prop]
      list = artworksIds.map((uid) =>
        prepareArtworkData($ngaConfig.artworks[uid]),
      )
    }
  }
</script>

<section>
  <h2 class="ma4 f3">FEATURED WORKS OF ART</h2>
  <hr />
  <div class="flex flex-column flex-row-ns flex-wrap justify-around">
    {#each list as data}
      <ArtworkItem {data} />
    {/each}
  </div>
</section>
