<script>
  import { getSearchTheCollectionTagCountUrl } from '../../utils'
  import { prepareArtworkData } from '../../utils'
  import ArtworkItem from '../common/ArtworkItem.svelte'

  export let data = null
  export let tag

  let buttons = []
  let list = []
  let tagCount = 0

  const getCount = (d) => Object.values(d).reduce((p, c) => p + c, 0)

  const addButton = (label, countProp, searchField) => {
    const obj = data.count[countProp]

    if (obj !== undefined) {
      const count = getCount(obj)
      const vocab = Object.keys(obj).reduce((prev, v) => {
        return `${prev}(${v}) `
      }, '')
      buttons.push({
        url: getSearchTheCollectionTagCountUrl(tag, searchField),
        label: `${vocab} - ${label}`,
        btLabel: `VIEW ${count} ARTWORK${count > 1 ? 'S' : ''}`,
      })

      tagCount += count
    }
  }

  $: {
    if (data !== null) {
      buttons = []
      tagCount = 0
      list = data.artworks.map(prepareArtworkData)

      addButton('Subjects/Concepts', 'subjectTerm', 'subjectTerTerm')
      addButton(
        'Periods and Art Movements',
        'stylePeriodTerm',
        'stylePeriodTerTerm',
      )
      addButton('Curriculum Terms', 'eduCurriculumTerm', 'eduCurriculumTerTerm')
    }
  }
</script>

<section>
  <div class="flex flex-column flex-row-ns flex-wrap justify-around">
    {#each list as data}
      <ArtworkItem {data} />
    {/each}
    <div class="w-100 w-50-m w-20-l flex flex-column justify-center">
      {#each buttons as bt}
        <div class="flex flex-row items-center justify-center mt2">
          <a
            class="va-bt f6 b flex-shrink-0"
            href={bt.url}
            target="_blank"
            title={bt.btLabel}>{bt.btLabel}</a
          >
        </div>
      {/each}
    </div>
  </div>
</section>

<style>
  .va-bt {
    width: 210px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: white;
    margin: 0 0 2rem 0;
  }

  .va-bt:first-child {
    margin-bottom: 2rem;
  }

  .va-bt:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.75);
  }
</style>
