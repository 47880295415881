<script>
  import { createEventDispatcher } from 'svelte'
  import { slide } from 'svelte/transition'

  import { ngaConfig } from '../../stores'
  import AccordionItem from '../common/AccordionItem.svelte'

  const dispatch = createEventDispatcher()

  export let data = null

  let interestingData = {
    totalCount: 0,
    interestingPlace: {
      name: "",
      count: 0,
      tgn: 0
    },
    mostPieces: {
      country: {
        name: "",
        count: 0,
        tgn: 0
      },
      state: {
        name: "",
        count: 0,
        tgn: 0
      },
      city: {
        name: "",
        count: 0,
        tgn: 0
      }
    },
    leastPieces: {
      country: {
        name: "",
        count: 0,
        tgn: 0
      },
      state: {
        name: "",
        count: 0,
        tgn: 0
      },
      city: {
        name: "",
        count: 0,
        tgn: 0
      }
    }
  }

  const constructInterestingData = () => {
    calulateTotalCount()
    findCountryWithMostPieces()
    findStateWithMostPieces()
    findCityWithMostPieces()
    findCountryWithLeastPieces()
    findStateWithLeastPieces()
    findCityWithLeastPieces()
  }

  const findInterestingPlace = () => {
    data.children.forEach(country => {
      if (country.name == interestingData.interestingPlace.name) {
        interestingData.interestingPlace.count = country.count;
        interestingData.interestingPlace.tgn = country.tgn;
      }
      country.children.forEach(state => {
        if (state.name == interestingData.interestingPlace.name) {
          interestingData.interestingPlace.count = state.count;
          interestingData.interestingPlace.tgn = state.tgn;
        }
        state.children.forEach(city => {
          if (city.name == interestingData.interestingPlace.name) {
            interestingData.interestingPlace.count = city.count;
            interestingData.interestingPlace.tgn = city.tgn;
          }
        })
      })
    })
  }

  const findCountryWithLeastPieces = () => {
    interestingData.leastPieces.country.count = interestingData.totalCount
    data.children.forEach(country => {
      if(interestingData.leastPieces.country && parseInt(country.count) < parseInt(interestingData.leastPieces.country.count)) {
        interestingData.leastPieces.country = country;
      }
    })
  }

  const findStateWithLeastPieces = () => {
    interestingData.leastPieces.state.count = interestingData.totalCount
    data.children.forEach(country => {
      country.children.forEach(state => {
        if(interestingData.leastPieces.state && parseInt(state.count) < parseInt(interestingData.leastPieces.state.count)) {
          interestingData.leastPieces.state = state
        }
      })
    })
  }

  const findCityWithLeastPieces = () => {
    interestingData.leastPieces.city.count = interestingData.totalCount
    data.children.forEach(country => {
      country.children.forEach(state => {
        state.children.forEach(city => {
          if(interestingData.leastPieces.city && parseInt(city.count) < parseInt(interestingData.leastPieces.city.count)) {
            interestingData.leastPieces.city = city
          }
        })
      })
    })
  }

  const findCountryWithMostPieces = () => {
    interestingData.mostPieces.country = data.children[1]
    data.children.forEach(country => {
      if(interestingData.mostPieces.country && parseInt(country.count) > parseInt(interestingData.mostPieces.country.count)) {
        interestingData.mostPieces.country = country
      }
    })
  }

  const findStateWithMostPieces = () => {
    interestingData.mostPieces.state = data.children[1].children[0]
    data.children.forEach(country => {
      country.children.forEach(state => {
        if(interestingData.mostPieces.state && parseInt(state.count) > parseInt(interestingData.mostPieces.state.count)) {
          interestingData.mostPieces.state = state
        }
      })
    })
  }

  const findCityWithMostPieces = () => {
    interestingData.mostPieces.city = data.children[1].children[0].children[0]
    data.children.forEach(country => {
      country.children.forEach(state => {
        state.children.forEach(city => {
          if(interestingData.mostPieces.city && parseInt(city.count) > parseInt(interestingData.mostPieces.city.count)) {
            interestingData.mostPieces.city = city
          }
        })
      })
    })
  }

  const calulateTotalCount = () => {
    interestingData.totalCount = 0
    data.children.forEach(child => {
      interestingData.totalCount += parseInt(child.count)
    })
  }

  const getNodeFromTGN = (tgn) => {
    let node = data
    data.children.forEach(child => {
      if (parseInt(child.tgn) == parseInt(tgn)) {
        node = child
      }
    })
    return node
  }

  const viewOnMapClick = (tgn) => {
    dispatch('viewOnMap', {
      text: tgn
    })
  }

  //  ACCORDIONS
  let accordionData = {
    'About this map': '<p class="f5-ns">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p><p class="f5-ns">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>You can <a class=" href="https://connect.nga.gov.au/events?k=General%20Entry">book online</a>, call +61 2 6240 6411 or email <a class=" href="mailto:ticketing@nga.gov.au">ticketing@nga.gov.au</a></p>'
  }

  let active_facts = {
    "accordion_title": "",
    "show_facts": false,
    "total_art": true,
    "interesting_place": "",
    "country_most_pieces": false,
    "state_most_pieces": false,
    "city_most_pieces": false,
    "country_least_pieces": false,
    "state_least_pieces": false,
    "city_least_pieces": false,    
  }

  const constructAccordionData = () => {
    let gatheredAccordionData = $ngaConfig && $ngaConfig['all'].infoAccordions
    accordionData = new Array()
    if (gatheredAccordionData) {
      Object.entries(gatheredAccordionData).forEach(([title, content]) => {
        let accordionTitle = title
        let accordionContent = ""
        Object.entries(content).forEach(([key, paragraph]) => {
          accordionContent += '<p class="f5-ns">'
          accordionContent += paragraph
          accordionContent += '</p>'
        })
        accordionData[accordionTitle] = accordionContent
      })
    }    
  }

  const activateFacts = () => {
    let interactiveAccordionFacts = $ngaConfig && $ngaConfig['all'].interactiveAccordion
    active_facts["show_facts"] = false
    if(interactiveAccordionFacts) {
      Object.entries(interactiveAccordionFacts).forEach(([title, content]) => {
        active_facts["accordion_title"] = title
        if(content["interesting_place"] != "" && content["interesting_place"]) {
          interestingData.interestingPlace.name = content["interesting_place"]
        }
        Object.entries(content).forEach(([key, state]) => {
          active_facts[key] = state
          if (state == true) {
            active_facts["show_facts"] = true
          }
        })
      })
    }
    findInterestingPlace()
  }
  
  //  INTERESTING DATA ACCORDION
  let isOpen = ''
  let ariaExpanded = false

  const toggleSlide = () => {
    if (isOpen != 'is-open') {
      isOpen = 'is-open'
      ariaExpanded = true
    } else {
      isOpen = ''
      ariaExpanded = false
    }
  }

  $: {
    if (data !== null) {
        constructInterestingData()
        constructAccordionData()
        activateFacts()
    }   
  }
</script>
    
<div class="mh4 mb4 flex flex-column">
  {#each Object.entries(accordionData) as itemData}
    <AccordionItem {itemData} />
  {/each}
  {#if active_facts["show_facts"]}
    <div class="block-accordion-small textblock-align">
      <div class="block-accordion-small__item">
        <h3 class="block-accordion-small__header">
          <button on:click={toggleSlide} class="block-accordion-small__toggle-btn js-slide-toggle {isOpen}" id="_6x7snqy70" aria-controls="_4x7m9tgm9" aria-expanded="{ariaExpanded}">
            {active_facts["accordion_title"]}
            <span class="icon"><svg class="svg-inline--fa fa-nga-iconset-arrow-down" aria-hidden="true" focusable="false" data-prefix="fak" data-icon="nga-iconset-arrow-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="" style="width: 1em;"><path fill="currentColor" d="M404.8 265.28L272 398.08V52.8h-32v345.28l-132.8-132.8L84.8 288 256 459.2 427.2 288l-22.4-22.72z"></path></svg><!-- <i class="fak fa-nga-iconset-arrow-down"></i> --></span>
          </button>
        </h3>
        {#if ariaExpanded}            
          <div transition:slide={{ duration: 500 }} class="block-accordion-small__pane js-slide-toggle-pane" id="_4x7m9tgm9" aria-labelledby="_6x7snqy70" aria-expanded="{ariaExpanded}" role="region" style="">
            <div class="block-accordion-small__pane-inner clearfix">
              <div class="block-richtext textblock-align">
                <div class="richtext default">
                  <ul class="f5-ns mb0">
                    {#if active_facts["total_art"]}
                      <li>
                        This map shows the place of creation of  {interestingData.totalCount} works of art across Africa, the Americas, Australia, Asia, Europe and the Pacific. 
                        {#if interestingData.interestingPlace.name != ""}
                          There is even {interestingData.interestingPlace.count} works that were created in <a class="view-on-map-button" on:click={() => { viewOnMapClick(interestingData.interestingPlace.tgn) }} data-tgn="{interestingData.interestingPlace.tgn}">{interestingData.interestingPlace.name}</a>.
                        {/if}
                      </li>
                    {/if}
                    {#if active_facts["country_most_pieces"]}
                      <li>
                        The country with the most works is {interestingData.mostPieces.country.name} with {interestingData.mostPieces.country.count}. <a class="view-on-map-button" on:click={() => { viewOnMapClick(interestingData.mostPieces.country.tgn) }} data-tgn="{interestingData.mostPieces.country.tgn}">View on map</a>.
                      </li>
                    {/if}
                    {#if active_facts["state_most_pieces"]}
                      <li>
                        The state with the most works is {interestingData.mostPieces.state.name} with {interestingData.mostPieces.state.count}. <a class="view-on-map-button" on:click={() => { viewOnMapClick(interestingData.mostPieces.state.tgn) }} data-tgn="{interestingData.mostPieces.state.tgn}">View on map</a>.
                      </li>
                    {/if}
                    {#if active_facts["city_most_pieces"]}
                      <li>
                        The city with the most works is {interestingData.mostPieces.city.name} with {interestingData.mostPieces.city.count}. <a class="view-on-map-button" on:click={() => { viewOnMapClick(interestingData.mostPieces.city.tgn) }} data-tgn="{interestingData.mostPieces.city.tgn}">View on map</a>.
                      </li>
                    {/if}
                    {#if active_facts["country_least_pieces"]}
                      <li>
                        The country with the least works is {interestingData.leastPieces.country.name} with {interestingData.leastPieces.country.count}. <a class="view-on-map-button" on:click={() => { viewOnMapClick(interestingData.leastPieces.country.tgn) }} data-tgn="{interestingData.leastPieces.country.tgn}">View on map</a>.
                      </li>
                    {/if}
                    {#if active_facts["state_least_pieces"]}
                      <li>
                        The state with the least works is {interestingData.leastPieces.state.name} with {interestingData.leastPieces.state.count}. <a class="view-on-map-button" on:click={() => { viewOnMapClick(interestingData.leastPieces.state.tgn) }} data-tgn="{interestingData.leastPieces.state.tgn}">View on map</a>.
                      </li>
                    {/if}
                    {#if active_facts["city_least_pieces"]}
                      <li>
                        The city with the least works is {interestingData.leastPieces.city.name} with {interestingData.leastPieces.city.count}. <a class="view-on-map-button" on:click={() => { viewOnMapClick(interestingData.leastPieces.city.tgn) }} data-tgn="{interestingData.leastPieces.city.tgn}">View on map</a>.
                      </li>
                    {/if}
                  </ul>        
                </div>
              </div>
            </div>
          </div>
        {/if}
      </div>    
    </div>
  {/if}
</div>



<style>
  .view-on-map-button {
    cursor: pointer;
    margin-left: 0px;
  }
  .block-accordion-small__header {
    margin-bottom: 0;
  }
  .block-accordion-small__toggle-btn {
    font-size: 1.6rem;
    font-weight: bold;
    text-transform: none;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: none;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    position: relative;
    padding-left: 3rem;
  }
  .block-accordion-small__toggle-btn:hover {
    background-color: transparent;
    color: var(--black);
  }
  .block-accordion-small__toggle-btn .icon {
    font-size: 1.6rem;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.2s ease-in-out;
  }
  .block-accordion-small__toggle-btn.is-open .icon {
    transform: rotate(180deg) translateY(50%);
  }
  .block-accordion-small__pane-inner {
    padding-bottom: 2rem;
    padding-top: 1.5rem;
  }
  .block-accordion-small__item {
    padding-top: 1rem;
  }
  .block-accordion-small__item button {
    background-color: transparent;
  }
</style>