import App from './App.svelte'

//const el = document.querySelector('.page-main__inner')
const el = document.querySelector('.page-main')


const app = new App({
	target: el,
})

export default app
