<script>
	export let title = ''
	export let classNames = ''
</script>
<section class="w-100 mv4 {classNames}">
	<hr />
	<h2 class="mh4 mt5 mb3 f5 lh-solid normal">{title}</h2>
	<div class="mh4">
		<slot></slot>
	</div>
</section>