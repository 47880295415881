<script>
  import AlphabeticalSection from '../components/home/AlphabeticalSection.svelte'
  import MapSection from '../components/home/MapSection.svelte'
  import FeaturedTagsSection from '../components/home/FeaturedTagsSection.svelte'
  import HeroSection from '../components/home/HeroSection.svelte'
  import PopularTagsSection from '../components/home/PopularTagsSection.svelte'
  import { filter } from '../stores'
  import FeaturedArtworksSection from '../components/home/FeaturedArtworksSection.svelte'
</script>

<HeroSection />
{#if $filter == 'tag'}<MapSection />{/if}
<FeaturedArtworksSection />
<FeaturedTagsSection />
<PopularTagsSection />
<AlphabeticalSection />
<!-- Temporarily disabling the map section on the learn page at the client request until they ask for it back -->
<!-- {#if $filter == 'learn'}<MapSection />{/if} --> 
