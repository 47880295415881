<script>
  import { onDestroy, onMount } from 'svelte'

  export let data = null

  let el
  let width = 800
  let height = 588
  let bars = []

  const resizeOb = new ResizeObserver((entries) => {
    let rect = entries[0].contentRect
    // usdate width to resize svg
    width = rect.width
    // height = Math.min(Math.floor(window.innerHeight * 0.6), 580)
    height = Math.min(Math.floor(width * 0.5), 580)
    // recalculate items too
    update()
  })

  onMount(() => resizeOb.observe(el))

  onDestroy(() => {
    resizeOb.unobserve(el)
    resizeOb.disconnect()
  })

  const update = () => {
    const barWidth = width / data.length

    const maxCount = data.reduce((t, c) => {
      if (c.count > t) return c.count
      return t
    }, 0)

    const maxHeight = height - 40

    bars = data.map((item, index) => {
      const x = index * barWidth
      const h = (item.count / maxCount) * maxHeight

      return {
        ...item,
        x,
        y: height - h,
        w: barWidth,
        h,
      }
    })
  }

  $: {
    if (data !== null) {
      update(data)
    }
  }
</script>

<div bind:this={el}>
  <svg viewBox="0 0 {width} {height}" width={width} height={height}>
    <defs>
      <linearGradient id="myGradient" gradientTransform="rotate(90)">
        <stop offset="5%" stop-color="#EDF8B1" />
        <stop offset="20%" stop-color="#C7E9B4" />
        <stop offset="50%" stop-color="#7FCDBB" />
        <stop offset="70%" stop-color="#41B6C4" />
        <stop offset="95%" stop-color="#1D91C0" />
      </linearGradient>
    </defs>

    <rect
      x="0"
      y="0"
      {height}
      {width}
      style="fill:url('#myGradient')"
      mask="url(#myMask)"
    />

    <mask id="myMask">
      <g transform="translate(0, 0)" style="fill:rgb(255,255,255)">
        {#each bars as bar}
        <rect x={bar.x} y={bar.y} width={bar.w} height={bar.h} />
        {/each}
      </g>
    </mask>

    {#each bars as bar}
    <a class="rect-link" xlink:href={bar.url} target="_blank">
      <rect x={bar.x} y={bar.y} width={bar.w} height={bar.h} fill='transparent' />
    </a>
    {/each}

    <g transform="translate(0, 0)">
      {#each data as item, index}
        <line
          x1={index * (width / data.length)}
          y1="0"
          x2={index * (width / data.length)}
          y2={height}
          stroke-width="1"
          stroke="rgba(0,0,0,0.2)"
        />
        <g transform="translate({index * (width / data.length)}, 0)">
          <a xlink:href={item.url} target="blank">
          <text class="subtitle f7 f6-ns" fill="black" y="14" x="4">{item.label}</text>
          <text class="subtitle f7 f6-ns" fill="black" y="30" x="4">({item.count})</text>
        </a>
        </g>
      {/each}
    </g>
    <line
      x1={width}
      y1="0"
      x2={width}
      y2={height}
      stroke-width="1"
      stroke="rgba(0,0,0,0.2)"
    />
    <line
      x1="0"
      y1={height}
      x2={width}
      y2={height}
      stroke-width="1"
      stroke="rgba(0,0,0,0.2)"
    />
  </svg>
</div>

<style>
  svg {
    background-color: #fefefe;
    width: 100%;
    /* height: 588px; */
  }

  .rect-link rect {
    stroke-width: 1;
    stroke: transparent;
    transition: all 0.3s ease-in-out;
  }

  .rect-link rect:hover {
    stroke-width: 1;
    stroke: black;
  }
</style>
