import { writable } from 'svelte/store'

const createTagsList = () => {
  const { subscribe, set } = writable(null)
  let loaded = false

  return {
    subscribe,
    async load () {
      // only load once!
      if (loaded) return
      loaded = true
      const res = await fetch(`/data/tags-count.json`)
      const data = await res.json()
      set(data)
    },
  }
}

const createCurrentTag = () => {
  const { subscribe, set } = writable({ tag: null, data: null })

  return {
    subscribe,
    async load (tagIndex) {
      try {
        const res = await fetch(`/data/tags/${tagIndex}.json`)
        const data = await res.json()
        set({ data, tag: data.tag })
      } catch (e) {}
    },
    reset () {
      set({ data: null, tag: null })
    },
  }
}

export const tagsList = createTagsList()
export const currentTag = createCurrentTag()
