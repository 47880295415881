// import { location } from 'svelte-spa-router'
import { derived, writable } from 'svelte/store'
import { tagsList } from './components/tags/stores'

const location = writable([window.location.pathname])
export const filter = derived(location, $location => {
  return $location.indexOf('/explore/learn') > -1 ? 'learn' : 'tag'
})

export const filteredTags = derived(
  [filter, tagsList],
  ([$filter, $tagsList]) => {
    if ($tagsList === null) return null

    const tags = $tagsList.tags
    if ($filter === 'learn') {
      const keys = Object.keys(tags)
      const eduTags = {}

      keys.forEach(key => {
        if (tags[key].ed !== undefined && tags[key].ed.ScOT !== undefined) {
          eduTags[key] = Object.assign({}, tags[key])
        }
      })

      return eduTags
    }
    return tags
  },
)

// Load config from
// https://digital.nga.gov.au/tag/tag-config.json

const initNgaCongif = () => {
  const { subscribe, set } = writable(null)

  return {
    subscribe,
    async load () {
      try {
        const res = await fetch('/data/nga-config-artworks.json')
        const data = await res.json()
        set(data)
      } catch (e) {
        console.log(e)
      }
    },
  }
}

export const ngaConfig = initNgaCongif()
