<script>
  import { filter, ngaConfig } from '../../stores'

  let bannerImageUrl = null
  let bannerLinkUrl = ''

  $: {
    if ($ngaConfig && $filter) {
      const prop = $filter === 'learn' ? 'edu' : 'all'

      bannerImageUrl = $ngaConfig[prop].bannerImage
      bannerLinkUrl = $ngaConfig[prop].bannerLinkUrl
    }
  }
</script>

<div class="relative white holder mt4">
  <img class="absolute" src={bannerImageUrl} alt="Details here" />
  <h2 class="mh4 mt5 mb2 f5 lh-solid normal">COLLECTION</h2>
  <div class="mh4">
    <h3 class="f1 lh-solid ma0 mb2 ttc">Search the <br />collection</h3>
    <a
      class="mt3 mb5"
      href={bannerLinkUrl}
      target="_blank"
      title="Browse the collection">BROWSE THE COLLECTION</a
    >
  </div>
</div>

<style>
  .holder {
    height: 400px;
    z-index: 0;
  }

  h3 {
    font-family: 'nga-cond';
  }

  a {
    align-items: center;
    border-radius: 5px;
    border: 1px solid #ffffff;
    color: white;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    line-height: 120%;
    /* text-transform: uppercase; */
    width: 209px;
  }

  a:hover {
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.25);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
</style>
