<script>
  import { Router, Route } from 'svelte-routing'
  import BreadcrumbNav from './components/BreadcrumbNav.svelte'
  import Home from './routes/Home.svelte'
  import Tag from './routes/Tag.svelte'
  import Map from './routes/Map.svelte'
  import NotFound from './routes/NotFound.svelte'
  import { tagsList } from './components/tags/stores'
  import { onMount } from 'svelte'
  import { ngaConfig } from './stores'

  export let url = ''

  onMount(() => {
    tagsList.load()
    ngaConfig.load()
  })


  // navigate to learn page temporarily
  if (window.location.pathname == "/") {
    window.location.href = '/explore/learn'
  }

</script>

<Router {url}>
  <main class="flex flex-column">
    <BreadcrumbNav />
    <Route path="/"><Home /></Route>
    <Route path="explore/learn"><Home /></Route>
    <Route path="explore/learn/view/:tagname" let:params><Tag {params} /></Route>
    <Route path="explore/map"><Map /></Route>
    <Route path="explore/map/:countinent/:country/:city" let:params><Map {params} /></Route>
    <Route path="explore/tag"><Home /></Route>
    <Route path="explore/tag/view/:tagname" let:params><Tag {params} /></Route>
    <Route path="not-found" component={NotFound} />
    <Route component={NotFound} />
  </main>
</Router>
