<script>
  import { filter, filteredTags } from '../../stores'
  import { getTagObjTotalCount } from '../../utils'

  const groups = {}
  let keys = []
  let currentKey = ''
  let currentGroup = []

  const setKey = (key) => {
    currentKey = key
    currentGroup = groups[key]
  }

  const groupTags = (data) => {
    const tags = Object.keys(data)
    tags.sort((a, b) => {
      if (a.toLowerCase() < b.toLowerCase()) return -1
      if (a.toLowerCase() > b.toLowerCase()) return 1
      return 0
    })

    tags.forEach((tag) => {
      const s = tag.toUpperCase().charAt(0)

      if (groups[s] === undefined) {
        groups[s] = []
      }

      groups[s].push({ name: tag, count: getTagObjTotalCount(data[tag]) })
    })

    keys = Object.keys(groups).sort()
    setKey(keys[0])
  }

  $: if ($filteredTags !== null) {
    groupTags($filteredTags)
  }
</script>

<section>
  <h2 class="ma4 f3">{currentKey}</h2>
  <hr />
  <div class="flex flex-row justify-between flex-wrap mh4 mv2">
    {#each keys as key}
      <button
        class="ph0 pv1 ma1 f5 {key == currentKey ? 'selected' : ''} "
        on:click={() => setKey(key)}>{key}</button
      >
    {/each}
  </div>
  <hr />
  <div class="flex flex-row current-tags items-start ma4">
    {#each currentGroup as tag}
      <a
        href="/explore/{$filter}/view/{tag.name}"
        class="tag-item f6 w-50 w-25-l"
        title={tag.name}>{tag.name} ({tag.count})</a
      >
    {/each}
  </div>
</section>

<style>
  button {
    cursor: pointer;
    width: 44px;
    background: white;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #fefefe;
  }

  button:hover {
    background: #000;
  }

  button.selected {
    border-color: #000;
    font-weight: bold;
  }

  a,
  a:link,
  a:visited {
    color: #000;
  }

  section {
    min-height: 36rem;
  }

  .current-tags {
    flex-wrap: wrap;
  }
</style>
