<script>
  import { slide } from 'svelte/transition'

  export let itemData;

    let isOpen = '';
    let ariaExpanded = false;

    const toggleSlide = () => {

        if (isOpen != 'is-open') {
            isOpen = 'is-open';
            ariaExpanded = true;
        } else {
            isOpen = '';
            ariaExpanded = false;
        }
    }




</script>

<div class="block-accordion-small textblock-align ">
    
    <div class="block-accordion-small__item">
        <h3 class="block-accordion-small__header ">
            <button on:click={toggleSlide} class="block-accordion-small__toggle-btn js-slide-toggle {isOpen}" aria-expanded="{ariaExpanded}">
                {@html itemData[0]}
                <span class="icon"><svg class="svg-inline--fa fa-nga-iconset-arrow-down" aria-hidden="true" focusable="false" data-icon="nga-iconset-arrow-down" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="" style="width: 1em;"><path fill="currentColor" d="M404.8 265.28L272 398.08V52.8h-32v345.28l-132.8-132.8L84.8 288 256 459.2 427.2 288l-22.4-22.72z"></path></svg><!-- <i class="fak fa-nga-iconset-arrow-down"></i> --></span>
            </button>
        </h3>
        {#if ariaExpanded}            
            <div transition:slide={{ duration: 500 }} class="block-accordion-small__pane js-slide-toggle-pane" aria-expanded="{ariaExpanded}" >
                <div class="block-accordion-small__pane-inner clearfix">
                    <div class="block-richtext textblock-align">
                        <div class="richtext default f5-ns">
                            {@html itemData[1]}
                        </div>
                    </div>
                </div>
            </div>
        {/if}
    </div>

</div>

<style>
  :global(a), :global(a:link) {
      text-decoration: underline !important;
  }

  .block-accordion-small__header {
    margin-bottom: 0;
  }

  .block-accordion-small__toggle-btn {
    font-size: 1.6rem;
    font-weight: bold;
    text-transform: none;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: none;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    position: relative;
    padding-left: 3rem;
  }
  .block-accordion-small__toggle-btn:hover {
    background-color: transparent;
    color: var(--black);
  }
  .block-accordion-small__toggle-btn .icon {
    font-size: 1.6rem;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 0.2s ease-in-out;
  }
  .block-accordion-small__toggle-btn.is-open .icon {
    transform: rotate(180deg) translateY(50%);
  }

  .block-accordion-small__pane-inner {
    padding-bottom: 2rem;
    padding-top: 1.5rem;
  }

  .block-accordion-small__item {
    padding-top: 1rem;
  }

  .block-accordion-small__item button {
    background-color: transparent;
  }
</style>
