<script>
  import { link } from 'svelte-routing'
  import { filter, filteredTags, ngaConfig } from '../../stores'
  import { getTagObjTotalCount } from '../../utils'
  import BaseSection from './BaseSection.svelte'
  import SearchTag from './SearchTag.svelte'

  export let data = null

  let related = []
  let list = []

  let dict = {}

  let sectionTitle = ''

  /*
	related: {
		subjectTerm: {
			tagName: { vocab: count }
		}
	}
	*/

  // get total count from tag-count.json instead of the related tag count
  const getTagTotalCount = (tag) => {
    if ($filteredTags[tag] === undefined) {
      return 0
    } else {
      return getTagObjTotalCount($filteredTags[tag])
    }
  }

  const addTag = (tag, vocabObj) => {
    for (let vocab in vocabObj) {
      const count = vocabObj[vocab]

      if (dict[tag] === undefined) {
        let url = `/explore/tag/view/${tag}`

        if (vocab.toLowerCase() === 'scot') {
          url = `/explore/learn/view/${tag}`
        }

        dict[tag] = { count, tag, url }
      } else if (dict[tag].count < count) {
        // dict[tag].count = count
      }
    }

    dict[tag].count = getTagTotalCount(tag)
  }

  $: {
    list = []
    related = []

    if (data.related !== undefined && Object.keys(data.related).length > 0) {
      const sources = Object.keys(data.related)

      dict = {}
      sectionTitle = 'RELATED TAGS'

      sources.forEach((source) => {
        const tags = Object.keys(data.related[source])
        tags.forEach((tag) => addTag(tag, data.related[source][tag]))
      })

      related = Object.keys(dict)
        .map((tag) => dict[tag])
        .sort((a, b) => {
          if (a.count > b.count) return -1
          if (a.count < b.count) return 1
          return 0
        })
        .slice(0, 10)
    } else {
      sectionTitle = 'POPULAR TAGS'

      if ($ngaConfig && $filter) {
        const prop = $filter === 'learn' ? 'edu' : 'all'
        const { popularTags } = $ngaConfig[prop]
        list = popularTags.map((tag) => ({
          url: `/explore/${$filter}/view/${tag}`,
          title: tag,
        }))
      }
    }
  }
</script>

<BaseSection title={sectionTitle} classNames="z-2">
  <div class="flex flex-column flex-row-l">
    <div class="w-100 w-50-l">
      <div class="flex flex-row flex-wrap">
        {#each related as r}
          <a
            href={r.url}
            class="f4 b lh-solid mt4 w-100 w-50-l"
            title={r.tag}
            use:link>{r.tag} ({r.count})</a
          >
        {/each}
        {#if related.length == 0}
          {#each list as item}
            <a
              href={item.url}
              class="f4 b lh-solid mv3 w-100 w-50-l"
              title={item.title}>{item.title}</a
            >
          {/each}
        {/if}
      </div>
    </div>
    <div class="w-100 w-50-l mt4 mt0-l mw6">
      <SearchTag />
    </div>
  </div>
</BaseSection>
