<script>
  import * as d3 from 'd3'
	import { onDestroy, onMount } from 'svelte'

  /*
    References:
    - https://observablehq.com/@d3/sunburst
    - https://observablehq.com/@d3/zoomable-sunburst
    - https://observablehq.com/@kerryrodden/sequences-sunburst
  */

  export let data = null

	const COLORS = [
		'#C7E9B4',
		'#7FCDBB',
		'#41B6C4',
		'#1D91C0',
		'#225EA8',
		'#253494',
		'#081D58',
	]

	const padding = 16

	let root
	let el
	let width = 800
	let height = 800

	let radius = width / 2

	let locationsCount = 0
	let svg
	let worldData = { name: 'World', children: [] }

	let textsGroup
	let placesGroup

	let format = d3.format(",d")

	let arc = d3.arc()
		.startAngle(d => d.x0)
		.endAngle(d => d.x1)
		.padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
		.padRadius(() => radius / 2)
		.innerRadius(d => d.y0)
		.outerRadius(d => d.y1 - 1)

	const partition = data =>
		d3.partition()
			.size([2 * Math.PI, radius])
			(d3.hierarchy(data)
				.sum(d => d.value)
				.sort((a, b) => b.value - a.value))

	onMount(() => {
		if (svg !== undefined) {
			// svg
			// 	.attr('viewBox', [-width / 2, -height / 2, width, height])
			// 	.attr('width', width)
			// 	.attr('height', height)

			width = Math.min(800, el.clientWidth)
			height = width

			updateSVGSize()

			el.appendChild(svg.node())
		}
	})

	const updateSVGSize = () => {
		radius = width / 2
		svg
			.attr('viewBox', [-width / 2, -height / 2, width, height])
			.attr('width', width)
			.attr('height', height)
	}

	/**
	 * Split hierarchy by semi colon and use first, second and last segment
	 * if bigger than 3
	 *
	 * @param str: String - Example '2000001:2078690:2872248:2720583:2159989'
	 */
	const getEssentialPlacesIds = (str) =>
		str
			.split(':')
			.filter((id, index, arr) => index <= 1 || (index >= 3 && index === arr.length - 1))

	const prepareD3Data = () => {
		const { tl, lo } = data
		const { hierarchy, ids } = lo

		locationsCount = Object.keys(hierarchy).length

		// console.log('Location data', lo)

		worldData = { name: 'World', id: 0, children: [] }

		for (const key in hierarchy) {
			if (Object.hasOwnProperty.call(hierarchy, key)) {
				const placesIds = getEssentialPlacesIds(key)

				let parent = worldData

				placesIds.forEach((id, index) => {
					const { term, c, url } = ids[id]

					// check if parent.children has this item
					let child = parent.children.find(item => item.name === term)

					if (child === undefined) {
						// new child object
						child = { name: term, id, url }

						if (index === placesIds.length -1) {
							// only add value a the last item of the chain
							child.value = c
						}

						child.children = []

						// console.log('child', child)
						parent.children.push(child)
					}
					// set parent as this current child to process next item
					parent = child
				})
			}
		}

		root = partition(worldData)

		// console.log('worldData', worldData)
	}

	const prepareD3 = () => {
		svg = d3.create("svg")
		svg.attr('width', '100%')
		svg.attr('height', '100%')

		placesGroup = svg.append("g")
			.attr("fill-opacity", 0.6)

		textsGroup = svg.append("g")
			.attr("pointer-events", "none")
			.attr("text-anchor", "middle")
			.attr("font-size", 10)
			.attr("font-family", "sans-serif")
	}

	const updateD3 = () => {
		// console.log('updateD3', worldData)
		let color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, worldData.children.length + 1))

		const colorDict = {}
		worldData.children.forEach((p, i) => colorDict[p.name] = COLORS[i])

		color = (name) => {
			return colorDict[name]
		}

		// color = COLORS

		// console.log('root', root)

		const pg = placesGroup
			.selectAll("path")
			.data(root.descendants().filter(d => d.depth), (d) => d.id)
		// update current paths
		pg
			.attr("fill", d => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
			.attr("d", arc)
			.attr("pathLength", function(d) {
				d3.select(this.parentNode).attr("xlink:href", d.data.url)
			})
			.selectAll("title")
			.text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${format(d.value)}`)

		// pg.attr('class', 'update')
	
		// add new paths
		pg.enter()
			.append('a')
			.attr("xlink:href", (d) => d.data.url)
			.attr('target', '_blank')
			.append('path')
		  .attr('class', 'loc-path')
			.attr("fill", d => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
			// .attr("fill", d => { while (d.depth > 1) d = d.parent; console.log(d.data);return COLORS[d.data.key]; })
			.attr("d", arc)
			.append("title")
			.text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${format(d.value)}`)

		// remove old paths
		pg.exit().each(function() { d3.select(this.parentNode).remove()} )

		const tg = textsGroup
			.selectAll("text")
			.data(
				root.descendants().filter(d => d.depth && (d.y0 + d.y1) / 2 * (d.x1 - d.x0) > 10),
				(d) => d.id
			)

			// Update current items
		tg
			.attr("transform", function(d) {
				const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
				const y = (d.y0 + d.y1) / 2;
				return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
			})
			.attr("dy", "0.35em")
			.text(d => {
				// if (d.data.value === undefined) console.log('text ', d)
				return `${d.data.name} (${d.value})`
			})

		// add new items
		tg.enter()
			.append("text")
			.attr("transform", function(d) {
				const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
				const y = (d.y0 + d.y1) / 2;
				return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
			})
			.attr("dy", "0.35em")
			.text(d => {
				// if (d.data.value === undefined) console.log('text ', d)
				return `${d.data.name} (${d.value})`
			})

			// remove old items
			tg.exit().remove()
	}

  const resizeOb = new ResizeObserver((entries) => {
    let rect = entries[0].contentRect
    // usdate width to resize svg
    width = Math.min(800, rect.width)
		height = width
    // recalculate items too
		updateSVGSize()
		prepareD3Data()
    updateD3()
  })

  let unsub

  onMount(() => {
    resizeOb.observe(el)
  })

  onDestroy(() => {
    resizeOb.unobserve(el)
    resizeOb.disconnect()
  })

	$: {
		if (data !== null) {
			// reprocess new data
			prepareD3Data()

			if (svg === undefined) {
				prepareD3()
			}

			updateD3()
		}
	}
</script>

<div bind:this={el}></div>
<span class="path-link"></span>

<style>
	:global(.loc-path) {
    stroke-width: 1;
    stroke: transparent;
    transition: all 0.3s ease-in-out;
  }

	:global(.loc-path:hover) {
		stroke-width: 1;
		stroke: black;
	}

	div {
		text-align: center;
	}
</style>
