<script>
  import ArrowLeft from './icons/ArrowLeft.svelte'


  let home = ''
  const stcUrl = STC_URL

  $: {
    const a = window.location.pathname.split('/')

    if (a.length < 2) {
      home = ''
    } else {
      switch (a[1]) {
        case 'learn':
          home = (a.length === 2) ? '' : '/explore/learn'
          break
        case 'map':
          home = ''
          break
        case '':
          home = ''
          break
        default:
          home = (WEBSITE_ENV === 'production') ? '/explore/learn' : '/' // temporarily re routing home for production
      }
    }
  }
</script>

<div class="flex flex-row mh4 mt5 f6 f5-ns">
  <a
    href="{stcUrl}"
    title="Search the Collection">SEARCH THE COLLECTION</a
  >
  <ArrowLeft className="mh2 self-center" />
  {#if home != ''}
    <a href={home}>LAUNCHPAD</a>
    <ArrowLeft className="mh2 self-center" />
  {/if}
</div>
