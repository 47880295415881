<script>
  import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js'
  import { onDestroy, onMount } from 'svelte'
  import ArrowLeft from '../icons/ArrowLeft.svelte'
  import MapBoxCustomControl from './MapBoxCustomControl'
  import { fade, fly } from 'svelte/transition'
  import { debug } from 'svelte/internal';

  export let data = null

  let debug_mode = false;

  const stcUrl = STC_URL

  const MAPBOX_CONTAINER = '#mapbox-container'

  const minRadius = 30
  const maxRadius = 250
  const diffRadius = maxRadius - minRadius

  // Australia
  const initialLngLat = { lat: -25, lng: 135 }

  const MAP_LAYERS = ['all']

  let fit_to_bounds_moving = true;

  let mouseLngOffset = 0

  let elInfo
  let map
  let zoom = 1
  let currentInfo = null
  let infoHasChildren = false
  let currentParentId = 'n0' // world
  let currentLayerLevel = 0

  const initMap = () => {
    // change token to be injected later
    mapboxgl.accessToken = MAPBOX_TOKEN



    map = new mapboxgl.Map({
      container: 'mapbox-el',
      style: MAPBOX_STYLE,
      center: initialLngLat,
      zoom,
      minzoom: 1,
      maxzoom: 8,
    })
    map.dragRotate.disable()
    map.touchZoomRotate.disableRotation()

    map.addControl(
      new MapBoxCustomControl({
        className: 'mapboxgl-ctrl-reset',
        title: 'Reset Map',
        eventHandler: onResetCtrlClick,
      }),
      'top-right',
    )

    map.addControl(
      new mapboxgl.FullscreenControl({
        container: document.querySelector(MAPBOX_CONTAINER),
      }),
    )

    map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right')

    

    map.on('load', addMapData)
    map.on('render', onMapRender)
    map.on('zoom', onZoom)

    map.on('click', (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['all', 'all-circle'],
      })

      //console.log(features);

      

      if (features.length === 0) {

        currentInfo = null

      } else {

        selectLocation(features[0].properties.tgn)

      }

      if (debug_mode) {
        var coordinates = e.lngLat;
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML('you clicked here: <br/>' + coordinates)
          .addTo(map);
      }
      
    })


    map.on('moveend', () => {
      fit_to_bounds_moving = false;
    });


    map.on('mousemove', (e) => {
      getMousePosition(e)
    })


    MAP_LAYERS.forEach((layerId) => {
      map.on('mouseenter', layerId, onMapMouseEnter)
      map.on('mouseleave', layerId, onMapMouseLeave)
    })

    map.getCanvas().style.cursor = 'cursor'
  }

  const addMapData = () => {
    let allPlaces = []

    const addItem = (c, depth = 0) => {
      if (c['name'] != 'World') {

        allPlaces.push({
          // feature for Mapbox DC
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [c.l[1], c.l[0]],
          },
          // properties: { name: c.name + ' ' + c.count, data: c }
          properties: Object.assign({ label: `${c.name}\n${c.count}` }, c),
        })
      }

      if (c.children) {
        c.children.forEach((item) => addItem(item, depth + 1))
      }
        
    }

    const worldCount = data.children.reduce(
      (prev, item) => prev + item.count,
      0,
    )

    const injectExtraProperties = (node, depth = 0, id = 'n0') => {
      node.id = id
      if (node.children !== undefined) {
        const totalCount = node.children.reduce((p, c) => p + c.count, 0)
        node.children.forEach((n, index) => {
          n.radius = minRadius + Math.round((n.count / worldCount) * diffRadius)
          n.depth = depth + 1
          n.parentId = id
          n = { ...injectExtraProperties(n, depth + 1, `${id}${index}`) }
        })
      }
      return { ...node }
    }

    addItem({
      ...data,
      id: 'n0', // world
      ...injectExtraProperties(data),
    })

    const addLayer = (sourceName, minzoom = 0, maxzoom = 24, color) => {
      

      map.addLayer({
        id: `${sourceName}-circle`,
        type: 'circle',
        source: sourceName,
        paint: {
          'circle-pitch-scale': 'viewport',
          'circle-color': [
            'step',
            ['get', 'depth'],
            '#FFFFD9',
            2,
            ['match', ['length', ['get', 'children']], 0, '#b0f2e3', '#7FCDBB'],
            3,
            ['match', ['length', ['get', 'children']], 0, '#8fcde7', '#1D91C0'],
          ],
          'circle-radius': ['get', 'radius'],
          'circle-blur': 0.75,
        },
      })

      map.addLayer({
        id: sourceName,
        type: 'symbol',
        source: sourceName,
        layout: {           //  Object Name on Map UI
          'text-field': [
            'format',
            ['get', 'name'],
            ['match', ['length', ['get', 'children']], 0, '', ' +'],
          ],
          'text-max-width' : 20,
          'text-size' : 16,
          'text-padding': 7,
          'text-font': ['NGA Regular', 'Arial Unicode MS Bold'],
          'text-offset': [0, 0],
          'text-anchor': 'center',
        },
      })
    }

    const addSourceLayer = (name, arr, minzoom, maxzoom, color) => {



      map.addSource(name, {
        type: 'geojson',
        generateId: true,
        data: {
          type: 'FeatureCollection',
          features: arr,
        },
      })

      addLayer(name, minzoom, maxzoom, color)
    }

    addSourceLayer('all', allPlaces, 0, 2.1, '#FFFFD9')

    currentParentId = data.id // world
    updateMapFilter()


    fixNodeCoords();
  }

  const updateMapFilter = () => {
    // update circles and texts
    map.setFilter('all', ['==', ['get', 'layer'], currentLayerLevel])
    map.setFilter('all-circle', ['==', ['get', 'layer'], currentLayerLevel])
  }


  const selectLocation = (tgn, set_layer_id = -1) => {    
    //  GET CORRECT NODE
    const node = searchNode(data, 'tgn', tgn)
    currentInfo = node;

    currentInfo.coordinates = getCoordsFromTgn(tgn);

    console.log("Get Node From Search:", currentInfo);

    if (set_layer_id > 0) {

    }

    if (currentInfo) {
      onInfoButtonClick()
    }
  }


  //  Fit node to map (Zoom in/Focus)
  const mapFitBounds = (node) => {
    if (node) {
      //console.log("mapFitBounds(node):",node);

      fit_to_bounds_moving = true;

      if (node.id === 'n0') {
        map.zoomTo(1)
      } else {
        const bounds = getNodeBounds(node)



        map.fitBounds(
          bounds, {
            padding: {
              top: 50,
              left: 50,
              right: 50,
              bottom: 50
            }
          }
        );

      }
    }
  }

  const onMapRender = () => {
    if (currentInfo !== null) {
      // syng to the map if it render a frame and currentInfo is valid
      syncCurrentInfo()

    }
  }


  const getCoordsFromTgn = (tgn) => {
    let coords = null;

    map.getSource('all')._data['features'].forEach((item) => {
      if (item.properties.tgn == tgn) {
        coords = item.geometry.coordinates;
      }
    });

    return coords;
  }

  const fixNodeCoords = () => {

    let fixed = new Array();

    map.getSource('all')._data['features'].forEach((item) => {

      let node =  searchNode(data, 'tgn', item.properties.tgn)

      node.l[0] = parseFloat(node.l[0]);
      node.l[1] = parseFloat(node.l[1]);


      let updated = false;

      

      if ((item.geometry.coordinates[1].toFixed(1) != parseFloat(node.l[0]).toFixed(1))) {

        //console.log(node.name," Lat => ", item.geometry.coordinates[1].toFixed(1), "<[cood]=[colAPI]>", node.l[0].toFixed(1));
        node.l[0] = parseFloat(item.geometry.coordinates[1].toFixed(1));

        updated = true;
      }

      if ((item.geometry.coordinates[0].toFixed(1) != parseFloat(node.l[1]).toFixed(1))) {
        //console.log(node.name," Lng => ", item.geometry.coordinates[0].toFixed(1), "<[cood]=[colAPI]>", node.l[1].toFixed(1));
        node.l[1] = parseFloat(item.geometry.coordinates[0].toFixed(1));
        
        updated = true;
      }

      if (updated) {
        //console.log(node.name," => ", node.l[0], "<[lat]=[lng]>", node.l[1]);
        fixed.push(node);
      }
      
      
    })

  }


  const getDifference = (a, b) => {
    return Math.abs(a - b);
  }




  const haversine = (location1, location2, km = true) => {

    const R = km ? 6371.071 : 3958.8; // Radius of the Earth in km or miles
    const rlat1 = location1.latitude * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = location2.latitude * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflon = (location2.longitude - location1.longitude) * (Math.PI / 180); // Radian difference (longitudes)

    return (
        2 * R *
        Math.asin(
            Math.sqrt(
                Math.sin(difflat / 2) * Math.sin(difflat / 2) +
                Math.cos(rlat1) *
                Math.cos(rlat2) *
                Math.sin(difflon / 2) *
                Math.sin(difflon / 2)
            )
        )
    );
  };



  // Map layer item
  const getNodeBounds = (node) => {

    
    //console.log("getNodeBounds():", (node.children));

    if (node.children) {

      if(node.children.length === 0) {

        let [lat, lng] = node.l


        return [
            [
              lat,
              lng
            ], // min latitude
            [
              lat,
              lng
            ], // max latitude
          ]
      }



      const { children } = node // JSON.parse(node.properties.children)


      let min_bounds = 2.5;

      //  Starting bounds at extremes of lat & lng
      let bounds = [
        [
          360,
          360
        ],
        [
          -360,
          -360
        ]
      ]

      let nodeBounds = {
        nodeFirstLat : null,
        nodeFirstLng : null,
        nodeLastLat : null,
        nodeLastLng : null
      }


      let max_parent_distance = 100;
      let [parent_lat, parent_lng] = node.l;



      let max_diff = 8000;
      if (node.layer == 1) {
        max_diff = 7000;
      } else if (node.layer == 2) {
        max_diff = 2500;
      }

      console.log('node.layer:', node.layer );

      children.forEach(item => {
        let [lat, lng] = item.l;
        
        
        let diff_km = haversine(
          {
            latitude: lat,
            longitude: lng
          },
          {
            latitude: parent_lat,
            longitude: parent_lng
          }
        );

        //console.log("max_diff:", max_diff);
        if (diff_km > max_diff) {
          console.log("skipping Location too far =>", diff_km.toFixed(2), item.name);
        } else {
          //  Fix for locations have have children across the -180/180 break in Lng&Lat
          //  eg. Pacific & USA
          if (parent_lng > 90 || parent_lng < -90) {
            let distanceFromParent = getDifference(parent_lng, lng);
            
            if (distanceFromParent < 180) {

              if (lng < 0) {
                lng += 360
              } else if (lng > 0) {
                lng -= 360
              }
              
            }
          }
          


          if (lat < bounds[0][1]) {
            bounds[0][1] = lat;
            nodeBounds.nodeFirstLat = item;
          };

          if (lng < bounds[0][0]) {
            bounds[0][0] = lng;
            nodeBounds.nodeFirstLng = item;
          };


          if (lat > bounds[1][1]) {
            bounds[1][1] = lat;
            nodeBounds.nodeLastLat = item;
          };

          if (lng > bounds[1][0]) {
            bounds[1][0] = lng;
            nodeBounds.nodeLastLng = item;
          };
        }
      });



      let lngDifference = getDifference(bounds[0][0], bounds[1][0]);
      let latDifference = getDifference(bounds[0][1], bounds[1][1]);

      if (lngDifference < min_bounds) {
        let newDistance = getDifference(lngDifference, min_bounds);
        bounds[0][0] -= newDistance/2;
        bounds[1][0] += newDistance/2;
      }
      if (latDifference < min_bounds) {
        let newDistance = getDifference(latDifference, min_bounds);
        bounds[0][1] -= newDistance/2;
        bounds[1][1] += newDistance/2;
      }




      console.log("nodeBounds:", nodeBounds);


      return bounds;

    }
  }

  let point_data = {
    x: 0,
    y: 0
  }

  let actual_point = {
    x: 0,
    y: 0
  }

  let paddingOnScreen = 50;

  const locationOnScreen = (point) => {

    let height = mapCanvas[0].clientHeight;
    let width = mapCanvas[0].clientWidth;

    if (
      point.x < (0 + paddingOnScreen) || point.y < (0 + paddingOnScreen) ||
      point.x > (width - paddingOnScreen) || point.y > (height - paddingOnScreen) 
    ) {
      return false;
    }

    return true;

  }

  let mouseClose = false;
  let locationDeviance = 120;

  const isMouseCloseToPosition = (position_data) => {
    if (
      mouse_position.x < (position_data.x + locationDeviance) && mouse_position.x > (position_data.x - locationDeviance) &&
      mouse_position.y < (position_data.y + locationDeviance) && mouse_position.y > (position_data.y - locationDeviance)
    ) {
      mouseClose = true;
    } else {
      mouseClose = false;
    }
    
    return mouseClose;
  }



  let debugHover;

  const syncCurrentInfo = () => {
    if (currentInfo !== null) {
      let [lat, lng] = currentInfo.l



      let pt = map.project({ lat, lng })

      let height = mapCanvas[0].clientHeight;
      let width = mapCanvas[0].clientWidth;

      if (pt.x < 0) {
        lng += 360;
      }

      pt = map.project({ lat, lng })

      while (pt.x > width) {
        lng -= 360;
        pt = map.project({ lat, lng })
      };

      

      elInfo.style.top = `${pt.y}px`
      elInfo.style.left = `${pt.x}px`

      actual_point = {
        x: lat,
        y: lng
      }


      if (debug_mode) {
        debugHover.style.top = `${pt.y}px`
        debugHover.style.left = `${pt.x}px`
      }
      
      point_data = {
        x: pt.x,
        y: pt.y
      }

      infoOnScreen = locationOnScreen(point_data);

      
    }
  }

  const searchNode = (node, prop, value, debug = false) => {

    if (debug) {
      console.log("searchNode",node, prop, value);

      console.log("(node[prop] === value) return node", (node[prop] === value), ":",node);
    }
    

    if (node[prop] === value) return node


    if (node.children !== null) {
      let result = null
      for (let i = 0; result === null && i < node.children.length; i++) {
        const n = node.children[i]

        if (n[prop] === value) {
          result = n
        } else {
          result = searchNode(n, prop, value)
        }
      }
      return result
    }


    return null
  }

  const nodeHasValidChildrenLocation = (node) => {
    if(!node) {
      return 0;
    };

    const { children } = node
    let valid = false
    let validCount = 0

    if (children !== undefined && children.length > 0) {
      children.forEach((c) => {
        if (c.l.length === 2 && (c.l[0] !== 0 || c.l[1] !== 0)) {
          validCount += 1
        }
      })
    }
    return validCount >= 1
  }

  onMount(() => initMap())

  onDestroy(() => map.remove())

  const onResetCtrlClick = (e) => {
    currentLayerLevel = 0
    currentParentId = 'n0'
    updateMapFilter()
    mapFitBounds(data)
  }

  const min_zoom_levels = {
    city            : 4.5,
    state           : 2,
    continent       : 0
  }

  //  When zooming
  const onZoom = (e) => {

    //  Do not run zoom updates if the map is fitting to bounds
    //  Keeps the correct layers showing for the focus
    if (fit_to_bounds_moving) {
      //console.log("fit_to_bounds_moving:", fit_to_bounds_moving);
      return;
    }

    let previousLayerLevel = currentLayerLevel;

    const zoom = e.target.getZoom()
    
    switch (true) {

      case zoom > min_zoom_levels.state && zoom < min_zoom_levels.city:  //  continent
        currentLayerLevel = 1   //  state
        break
      case zoom > min_zoom_levels.city:
        currentLayerLevel = 2   //  city
        break
      default:
        currentLayerLevel = 0   //  continent
    }


    if (previousLayerLevel != currentLayerLevel) {
      //console.log("Hide interactive button");
      currentInfo = null;
    }

    updateMapFilter()
  }


  let status = 'waiting...';
  let showInfoButton = true;
  let nextInfo = null;
  let hovering = false;
  let showInfoTimeout = 1000;
  let timer = 0;
  let infoOnScreen = false;

  const resetShowInfoTimer = () => {
    timer = showInfoTimeout;
  }

  setInterval(() => {
    if (timer > 0) {
      timer--;
    } else {
      if (!hovering && currentInfo != null) {
        currentInfo = null;
      }
    }
  }, 1000);

  const infoButtonOutroFinished = () => {
    if (currentInfo != null) {
      showInfoButton = true;
      showInfo(nextInfo);
    }
  }

  const showInfo = (node) => {

    if(!node) {
      currentInfo = null;
      return;
    }

    node.l[0] = parseFloat(node.l[0]);
    node.l[1] = parseFloat(node.l[1]);

    

    if ((node.coordinates[1].toFixed(1) != parseFloat(node.l[0]).toFixed(1))) {
      node.l[0] = node.coordinates[1].toFixed(1);
    }

    if ((node.coordinates[0].toFixed(1) != parseFloat(node.l[1]).toFixed(1))) {
      node.l[1] = node.coordinates[0].toFixed(1);
    }

    currentInfo = node

    infoHasChildren = nodeHasValidChildrenLocation(node)

    syncCurrentInfo()

    resetShowInfoTimer();


  }


  let mouse_position = {
    x: 0,
    y: 0
  }

  let mouse_live_position = {
    dom : {
      x: 0,
      y: 0
    },
    map : {
      x: 0,
      y: 0
    },
    projection : {
      x: 0,
      y: 0
    }
  }
  
  const getMousePosition = (e) => {

    mouse_live_position.map.x = e.lngLat.lat;
    mouse_live_position.map.y = e.lngLat.lng;

    let lat = e.lngLat.lat;
    let lng =  e.lngLat.lng;
    const pt = map.project({ lat, lng });

    mouse_live_position.projection.x = pt.x;
    mouse_live_position.projection.y = pt.y;
  }

  const onMapMouseEnter = (e) => {
    const { features } = e

    let lat = e.lngLat.lat;
    let lng =  e.lngLat.lng;

    const pt = map.project({ lat, lng })

    mouse_position.x = pt.x;
    mouse_position.y = pt.y;


    if (features.length > 0) {
      const node = searchNode(data, 'tgn', features[0].properties.tgn)
      
      node.coordinates = features[0].geometry.coordinates;



      if (currentInfo != null && currentInfo != node && showInfoButton) {
        nextInfo = node;
        showInfoButton = false;
      }

      map.getCanvas().style.cursor = 'pointer'
      hovering = true;

      if (!showInfoButton && nextInfo != null) {
        return;
      }

      showInfo(node);
      
    }
  }

  const onMapMouseLeave = (e) => {

    //console.log("onMapMouseLeave(e):", e);
    hovering = false;
    resetShowInfoTimer();

    nextInfo = null;

    map.getCanvas().style.cursor = 'default'
  }


  const onInfoButtonClick = () => {
    

    if (currentInfo) {
      
      if (currentInfo.children.length == 0) {
        console.log('Flyto:', currentInfo.coordinates, currentInfo.layer);

        


        currentLayerLevel = currentInfo.layer

        updateMapFilter()

        let zoom_level = 2;

        if (currentLayerLevel == 2) {
          zoom_level = min_zoom_levels.city;
        } else if (currentLayerLevel == 1) {
          zoom_level = min_zoom_levels.state;
        }



        
        map.flyTo({
          center: currentInfo.coordinates,
          zoom: zoom_level
        });

        fit_to_bounds_moving = true;
      } else {

        currentLayerLevel = currentInfo.layer + 1

        // use currentInfo to get bounds of its children before deselecting it
        mapFitBounds(currentInfo)

        
      }

      // selected item becomes the new parent
      currentParentId = currentInfo.id
      

      updateMapFilter()
      currentInfo = null
    }
  }

  const getNodeFromTGN = (tgn) => {
    //console.log("getNodeFromId():", data);
    let node = data;


    data.children.forEach(country => {

      if (parseInt(country.tgn) == parseInt(tgn)) {
        //console.log('comparing(country):', parseInt(country.tgn), parseInt(tgn), parseInt(country.tgn) == parseInt(tgn));
        node = country;
      }

      country.children.forEach(state => {
        if (parseInt(state.tgn) == parseInt(tgn)) {
          node = state;
          //console.log('comparing(state):', parseInt(state.tgn), parseInt(tgn), parseInt(state.tgn) == parseInt(tgn));
        }

        state.children.forEach(city => {

          if (parseInt(city.tgn) == parseInt(tgn)) {
            //console.log('comparing(city):', parseInt(city.tgn), parseInt(tgn), parseInt(city.tgn) == parseInt(tgn));
            node = city;
          }

        });

      });
    });

    return node;
  }


  export const viewOnMap = (tgn) => {
    console.log('viewOnMap(tgn):', tgn);

    selectLocation(tgn);
  }

  $: mapCanvas = document.getElementsByClassName("mapboxgl-canvas");

</script>


{#if debug_mode}
  <div class="f7">
    fit_to_bounds_moving: {fit_to_bounds_moving}<br />
    anim-status: {status} | infoOnScreen: {infoOnScreen} | showInfoButton: {showInfoButton}<br  />
    positions:<br />
    [x:{actual_point.x.toFixed(2)}|y:{actual_point.y.toFixed(2)}] = (projection Point) Location Point (data=locations.json) (actual_point)<br />
    [x:{point_data.x.toFixed(2)}|y:{point_data.y.toFixed(2)}] = currentInfo (projection Point)<br />
    [x:{mouse_position.x.toFixed(2)}|y:{mouse_position.y.toFixed(2)}] = mouseOnEnter (projection Point)<br />
    <hr />
    [x:{mouse_live_position.dom.x.toFixed(2)}|y:{mouse_live_position.dom.y.toFixed(2)}] = mouse_position.dom<br />
    [x:{mouse_live_position.map.x.toFixed(2)}|y:{mouse_live_position.map.y.toFixed(2)}] = mouse_position.map<br />
    [x:{mouse_live_position.projection.x.toFixed(2)}|y:{mouse_live_position.projection.y.toFixed(2)}] = mouse_position.projection<br />
    mouseLngOffset: {mouseLngOffset} | mouseClose => {mouseClose}
  </div>
{/if}

<div id="mapbox-container" class="map-container mh4 relative">
  <div id="mapbox-el" class="map w-100" />
    {#if debug_mode}
      <div bind:this={debugHover} class="debug-hover f7">debug</div>
    {/if}
    <div bind:this={elInfo} class="info flex flex-column items-center ">
    
      {#if showInfoButton}
        {#if currentInfo != null}
          <a
            class="info-link f5"
            target="_blank"
            href="{stcUrl}/results?keyword=*&selectedFilters=%5B%7B%22label%22:%22{currentInfo.name}%22,%22field%22:%22placeHierarchyId%22,%22value%22:%22{currentInfo.tgn}%22%7D%5D"
            title="View in Search The Collection"
            in:fly={{ x: -20, duration: 300 }}
            out:fade={{ duration: 100 }}
            on:introstart="{() => status = 'intro started'}"
            on:outrostart="{() => status = 'outro started'}"
            on:introend="{() => status = 'intro ended'}"
            on:outroend="{() => infoButtonOutroFinished()}"
            >View {currentInfo.count} Works of Art <ArrowLeft
              className="v-mid ml2"
            /></a>
        {/if}
      {/if}  
    </div>

  
</div>

<style>

  .debug-hover {
    background: rgba(255, 0, 0, 0.75);
    position: absolute;
    top: 20px;
    left: 20px;

  }

  .map-container {
    height: calc(100vh - 300px);
    min-height: 500px;
    position: relative;
    overflow: hidden;
  }

  .map {
    height: 100%;
    min-height: 500px;
  }

  :global(.mapboxgl-ctrl-top-right) {
    display: flex;
  }

  :global(.mapboxgl-ctrl-group button) {
    width: 40px;
    height: 40px;
  }

  :global(.mapboxgl-ctrl-reset .mapboxgl-ctrl-icon) {
    background-image: url('data:image/svg+xml;utf8,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="40" height="40" fill="white" fill-opacity="0"/><rect x="0.5" y="0.5" width="39" height="39" rx="4.5" fill="white" fill-opacity="0" stroke="black"/><path d="M20 6.99998L20 7.49998L20 6.99998ZM7.00001 20L6.50001 20L7.00001 20ZM20.5 32.5L20 32.5L20 33.5L20.5 33.5L20.5 32.5ZM20 7.49998L20.5 7.49998L20.5 6.49998L20 6.49998L20 7.49998ZM20.5 7.49998C27.1274 7.49998 32.5 12.8726 32.5 19.5L33.5 19.5C33.5 12.3203 27.6797 6.49998 20.5 6.49998L20.5 7.49998ZM32.5 20.5C32.5 27.1274 27.1274 32.5 20.5 32.5L20.5 33.5C27.6797 33.5 33.5 27.6797 33.5 20.5L32.5 20.5ZM7.50001 20C7.50001 13.0964 13.0965 7.49998 20 7.49998L20 6.49998C12.5442 6.49998 6.50001 12.5441 6.50001 20L7.50001 20ZM6.50001 20C6.5 27.4558 12.5442 33.5 20 33.5L20 32.5C13.0964 32.5 7.5 26.9035 7.50001 20L6.50001 20Z" fill="black"/><rect x="30" y="18" width="5" height="5" fill="white"/><path d="M33 19.5L32.5 19.5L32.5 19.5L33 19.5ZM32.6464 20.3536C32.8417 20.5488 33.1583 20.5488 33.3536 20.3536L36.5355 17.1716C36.7308 16.9763 36.7308 16.6597 36.5355 16.4645C36.3403 16.2692 36.0237 16.2692 35.8284 16.4645L33 19.2929L30.1716 16.4645C29.9763 16.2692 29.6597 16.2692 29.4645 16.4645C29.2692 16.6597 29.2692 16.9763 29.4645 17.1716L32.6464 20.3536ZM32.5 19.5L32.5 20L33.5 20L33.5 19.5L32.5 19.5ZM20.5 7.5C27.1274 7.5 32.5 12.8726 32.5 19.5L33.5 19.5C33.5 12.3203 27.6797 6.5 20.5 6.5L20.5 7.5Z" fill="black"/></svg>')
  }

  :global(.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon) {
    background-image: url('data:image/svg+xml;utf8,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="39" height="39" rx="4.5" stroke="black"/><path d="M33.5 7C33.5 6.72386 33.2761 6.5 33 6.5H28.5C28.2239 6.5 28 6.72386 28 7C28 7.27614 28.2239 7.5 28.5 7.5H32.5V11.5C32.5 11.7761 32.7239 12 33 12C33.2761 12 33.5 11.7761 33.5 11.5V7ZM25.3536 15.3536L33.3536 7.35355L32.6464 6.64645L24.6464 14.6464L25.3536 15.3536Z" fill="black"/><path d="M7 6.5C6.72386 6.5 6.5 6.72386 6.5 7V11.5C6.5 11.7761 6.72386 12 7 12C7.27614 12 7.5 11.7761 7.5 11.5V7.5H11.5C11.7761 7.5 12 7.27614 12 7C12 6.72386 11.7761 6.5 11.5 6.5H7ZM15.3536 14.6464L7.35355 6.64645L6.64645 7.35355L14.6464 15.3536L15.3536 14.6464Z" fill="black"/><path d="M6.5 33C6.5 33.2761 6.72386 33.5 7 33.5H11.5C11.7761 33.5 12 33.2761 12 33C12 32.7239 11.7761 32.5 11.5 32.5H7.5V28.5C7.5 28.2239 7.27614 28 7 28C6.72386 28 6.5 28.2239 6.5 28.5V33ZM14.6464 24.6464L6.64645 32.6464L7.35355 33.3536L15.3536 25.3536L14.6464 24.6464Z" fill="black"/><path d="M33 33.5C33.2761 33.5 33.5 33.2761 33.5 33V28.5C33.5 28.2239 33.2761 28 33 28C32.7239 28 32.5 28.2239 32.5 28.5V32.5H28.5C28.2239 32.5 28 32.7239 28 33C28 33.2761 28.2239 33.5 28.5 33.5H33ZM24.6464 25.3536L32.6464 33.3536L33.3536 32.6464L25.3536 24.6464L24.6464 25.3536Z" fill="black"/></svg>')
  }

  :global(.mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon) {
    background-image: url('data:image/svg+xml;utf8,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="39" height="39" rx="4.5" stroke="black"/><path d="M24.5 15C24.5 15.2761 24.7239 15.5 25 15.5H29.5C29.7761 15.5 30 15.2761 30 15C30 14.7239 29.7761 14.5 29.5 14.5H25.5V10.5C25.5 10.2239 25.2761 10 25 10C24.7239 10 24.5 10.2239 24.5 10.5V15ZM25.3536 15.3536L33.3536 7.35355L32.6464 6.64645L24.6464 14.6464L25.3536 15.3536Z" fill="black"/><path d="M15 15.5C15.2761 15.5 15.5 15.2761 15.5 15V10.5C15.5 10.2239 15.2761 10 15 10C14.7239 10 14.5 10.2239 14.5 10.5V14.5H10.5C10.2239 14.5 10 14.7239 10 15C10 15.2761 10.2239 15.5 10.5 15.5H15ZM15.3536 14.6464L7.35355 6.64645L6.64645 7.35355L14.6464 15.3536L15.3536 14.6464Z" fill="black"/><path d="M15.5 25C15.5 24.7239 15.2761 24.5 15 24.5H10.5C10.2239 24.5 10 24.7239 10 25C10 25.2761 10.2239 25.5 10.5 25.5H14.5V29.5C14.5 29.7761 14.7239 30 15 30C15.2761 30 15.5 29.7761 15.5 29.5V25ZM14.6464 24.6464L6.64645 32.6464L7.35355 33.3536L15.3536 25.3536L14.6464 24.6464Z" fill="black"/><path d="M25 24.5C24.7239 24.5 24.5 24.7239 24.5 25V29.5C24.5 29.7761 24.7239 30 25 30C25.2761 30 25.5 29.7761 25.5 29.5V25.5H29.5C29.7761 25.5 30 25.2761 30 25C30 24.7239 29.7761 24.5 29.5 24.5H25ZM24.6464 25.3536L32.6464 33.3536L33.3536 32.6464L25.3536 24.6464L24.6464 25.3536Z" fill="black"/></svg>')
  }

  :global(.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon) {
    background-image: url('data:image/svg+xml;utf8,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="40" height="40" fill="white" fill-opacity="0"/><rect x="0.5" y="0.5" width="39" height="39" rx="4.5" fill="white" fill-opacity="0" stroke="black"/><line x1="20" y1="7" x2="20" y2="33" stroke="black"/><line x1="33" y1="20" x2="7" y2="20" stroke="black"/></svg>')
  }

  :global(.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in) {
    margin-bottom: 10px;
  }

  :global(.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon) {
    background-image: url('data:image/svg+xml;utf8,<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="40" height="40" fill="white" fill-opacity="0"/><rect x="0.5" y="0.5" width="39" height="39" rx="4.5" fill="white" fill-opacity="0" stroke="black"/><line x1="33" y1="20" x2="7" y2="20" stroke="black"/></svg>')
  }

  :global(.mapboxgl-ctrl-group:not(:empty)) {
    box-shadow: none;
  }

  .info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 10px);
    width: 100%;
  }

  .info-link {
    font-style: italic;
    text-decoration: underline;
    font-size: 1.6rem;
    margin-top: -3px;
  }

  .info-button {
    border-radius: 50%;
    background-color: transparent;
    padding: 0;
    margin: 0.5rem;
    height: 26px;
    width: 26px;
    line-height: 26px;
    text-align: center;
    border: 1px solid #000;

  }

  .info-button:hover {
    background-color: #000;
    color: #fff;
  }

  @media screen and (min-width: 48em) {
    /* .map { height: 600px; } */
  }

  @media screen and (min-width: 96em) {
    /* .map { height: 800px; } */
  }
</style>
