<script>
  import { getSearchTheCollectionTagYearGapUrl, getTermsFromTagCount } from '../../utils'

  import BaseSection from './BaseSection.svelte'
  import TimelineChart from './charts/TimelineChart.svelte'

  const YEAR_GAP = 10
  const YEAR_START = 1900
  const YEAR_END = 2020

  export let data = null
  let highestObj = null

  // time with more records
  let highestPercent = 43

  let groups = []

  let hasTime = false

  let currentTerms = []

  const getYearGapUrl = (start, end) => {
    return getSearchTheCollectionTagYearGapUrl(
      data.tag,
      start,
      end,
      currentTerms,
    )
  }

  const getInitialGroups = () => {
    const a = [
      {
        label: 'Pre 1900s',
        year: -1,
        count: 0,
        gapLabel: 'Pre 1900s',
        url: '', // url to be generated after the smallest year is found.
      },
    ]

    let endYear

    for (let year = YEAR_START; year <= YEAR_END; year += YEAR_GAP) {
      endYear = year + YEAR_GAP - 1
      a.push({
        label: `${year}s`,
        gapLabel: `${year} - ${endYear}`,
        year,
        count: 0,
        url: getYearGapUrl(year, endYear),
      })
    }

    return a
  }

  const parseTimelineData = (timelineData) => {
    const years = Object.keys(timelineData)

    groups = getInitialGroups()

    let index

    years.forEach((key) => {
      const year = Number(key)

      if (year < YEAR_START) {
        // save all pre 1900 years into the same group
        // pre 1900
        if (year < groups[0].year) {
          // we need to save the smallest year to be
          // able to create a correct url for STC
          groups[0].year = year
        }

        index = 0
      } else if (year >= YEAR_END) {
        index = groups.length - 1
      } else if (Number.isNaN(year)) {
        console.log('Not a valid year number', year)
        return
      } else {
        // find index in a 10y gap
        index = Math.floor((year - YEAR_START) / YEAR_GAP)
        // as fist index of the group is the pre 1900
        index += 1
      }

      groups[index].count += timelineData[key]
    })

    // regenerate url for pre 1900 to use the smallest year
    groups[0].url = getYearGapUrl(groups[0].year, 1899)
  }

  const calculateHighestYearGap = () => {
    const totalCount = groups.reduce((total, item) => item.count + total, 0)
    highestObj = groups.reduce((prev, cur) => {
      if (cur.count > prev.count) {
        return cur
      }
      return prev
    }, groups[0])

    highestPercent = Math.floor((highestObj.count / totalCount) * 100)
  }

  $: {
    if (data.tl) {
      hasTime = Object.keys(data.tl).length > 0

      if (hasTime) {
        currentTerms = getTermsFromTagCount(data.count)
        parseTimelineData(data.tl)
        calculateHighestYearGap()
      }
    }
  }
</script>

<BaseSection title="TIME">
  {#if hasTime}
    {#if highestObj}
      <h3 class="f2 lh-solid ma0 mb5">
        {highestPercent}% of these work{#if highestObj.count > 1}s{/if} of art were created from
        <a
          href={highestObj.url}
          target="_blank"
          title={`View {highestObj.gapLabel} on Search The Collection`}
          class="highest-year-gap">{highestObj.gapLabel} ({highestObj.count})</a
        >.
      </h3>
    {/if}

    <TimelineChart data={groups} />
  {:else}
    <h3 class="f3 f2-ns ma0 lh-solid">No time associated with this tag.</h3>
  {/if}
</BaseSection>

<style>
  h3 {
    font-family: 'nga-cond';
  }
  .highest-year-gap {
    /* color: #FFFFD9; */
    color: #c7e9b4;
    text-decoration: underline;
  }
</style>
