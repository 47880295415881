<script>
import { getSearchTheCollectionTagObjectTypeUrl, getTermsFromTagCount } from '../../utils';

  import BaseSection from './BaseSection.svelte'
  import ObjectTypeChart from './charts/ObjectTypeChart.svelte'

  export let data = null

  let firstPercent = 23
  let firstObj = null

  let hasObjectTypes = false

  const calculateHeaderInfo = (objectTypeData) => {
    const arr = Object.keys(objectTypeData).map((key) => ({
      ...objectTypeData[key],
      key,
    }))
    const totalCount = arr.reduce((total, item) => item.c + total, 0)

    arr.sort((a, b) => {
      if (a.c > b.c) return -1
      if (a.c < b.c) return 1
      return 0
    })

    firstObj = arr[0]
    firstPercent = Math.floor((firstObj.c / totalCount) * 100)
  }

  const injectUrls = () => {
    const terms = getTermsFromTagCount(data.count)

    Object.keys(data.ot).forEach(objectType => {
      const o = data.ot[objectType]
      o.url = getSearchTheCollectionTagObjectTypeUrl(data.tag, objectType, terms)
    })
  }

  $: {
    if (data !== null) {
      injectUrls()
      hasObjectTypes = Object.keys(data.ot).length > 0
      if (hasObjectTypes) calculateHeaderInfo(data.ot)
    }
  }
</script>

<BaseSection title="OBJECT TYPE">
  {#if hasObjectTypes}
    <h3 class="f2 lh-solid ma0 mb5">
      {firstPercent}% of these artworks are
      <a href={firstObj.url} target="_blank" class="first">{firstObj.key} ({firstObj.c})</a>.
    </h3>
    <ObjectTypeChart {data} />
  {:else}
    <h3 class="f3 f2-ns ma0 lh-`solid">No object type associated with this tag.</h3>
    `
  {/if}
</BaseSection>

<style>
  h3 {
    font-family: 'nga-cond';
  }
  .first {
    color: #7fcdbb;
    text-decoration: underline;
  }
</style>
