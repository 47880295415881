import { writable } from 'svelte/store'

const createLocationNodes = () => {
  const { subscribe, set } = writable(null)

  return {
    subscribe,
    async load () {
      const res = await fetch(`/data/locations.json`)
      const data = await res.json()
      set(data)
    },
  }
}

export const locationsNodes = createLocationNodes()

export const selectedNode = writable(null)
