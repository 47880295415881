<script>
  import { filter, ngaConfig } from '../../stores'

  let list = []

  $: {
    if ($ngaConfig && $filter) {
      const prop = $filter === 'learn' ? 'edu' : 'all'
      const { popularTags } = $ngaConfig[prop]
      list = popularTags.map(tag => ({
        url: `/explore/${$filter}/view/${tag}`,
        title: tag
      }))
    }
  }
</script>
<section>
  <h2 class="ma4 f3">POPULAR TAGS</h2>
  <hr />
  <div class="flex flex-row flex-wrap ma4">
    {#each list as item}
    <a href={item.url} class="f4 b lh-solid mv3 w-100 w-50-l" title={item.title}>{item.title}</a>
    {/each}
  </div>
</section>
