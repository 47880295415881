<script>
  import { getSearchTheCollectionTagPlaceUrl, getTermsFromTagCount } from '../../utils';

  import BaseSection from './BaseSection.svelte'
  import LocationChart from './charts/LocationChart.svelte'

  export let data = null

  let place = 'Mount Kisco'
  let count = 200
  let hasLocations = false
  let placeObj = null
  let dataReady = false

  const getDeepestHighestCount = () => {
    // use hierarchy that doesnt have the aggregated count
    const items = Object.entries(data.lo.hierarchy).map(([key, value]) => [
      key.substr(key.lastIndexOf(':') + 1),
      value,
    ])
    items.sort((a, b) => {
      if (a[1] > b[1]) return -1
      if (a[1] < b[1]) return 1
      return 0
    })

    if (items.length > 0) {
      const [id, c] = items[0] // first items ofter sorting
      place = data.lo.ids[id].term
      count = c
      placeObj = data.lo.ids[id]
    }
  }

  // Inject urls to be used inside the chart.
  const injectUrls = () => {
    const terms = getTermsFromTagCount(data.count)

    Object.keys(data.lo.ids).forEach(id => {
      const o = data.lo.ids[id]
      o.url = getSearchTheCollectionTagPlaceUrl(data.tag, o.term, terms, id)
    })
  }

  $: {
    if (data.lo) {
      hasLocations = Object.keys(data.lo.hierarchy).length > 0
      if (hasLocations) {
        injectUrls()
        getDeepestHighestCount()
        dataReady = true
      }
    }
  }
</script>

<BaseSection title="LOCATION">
  {#if hasLocations && dataReady}
    <h3 class="f2 ma0 lh-solid mb5">
      Where the works of art were created: <a href={placeObj.url} class="place" target="_blank">{placeObj.term} ({placeObj.c})</a>.
    </h3>
    <LocationChart {data} />
  {:else}
    <h3 class="f3 f2-ns ma0 lh-solid">No location associated with this tag.</h3>
  {/if}
</BaseSection>

<style>
  h3 {
    font-family: 'nga-cond';
  }

  .place {
    color: #41b6c4;
    text-decoration: underline;
  }
</style>
