<script>
  import { onDestroy, onMount } from 'svelte'
  import { navigate } from 'svelte-routing'
  import ArtworksSection from '../components/tags/ArtworksSection.svelte'
  import BannerSection from '../components/tags/BannerSection.svelte'
  import Details from '../components/tags/Details.svelte'
  import LocationSection from '../components/tags/LocationSection.svelte'
  import ObjectTypeSection from '../components/tags/ObjectTypeSection.svelte'
  import RelatedTagsSection from '../components/tags/RelatedTagsSection.svelte'
  import { currentTag, tagsList } from '../components/tags/stores'
  import TimeSection from '../components/tags/TimeSection.svelte'

  export let params = {}

  let tag = null
  let data = null

  const unsub = currentTag.subscribe((value) => {
    tag = value.tag
    data = value.data
  })

  onMount(() => window.scrollTo({ top: 0, left: 0, behavior: 'auto' }))

  onDestroy(() => unsub())

  $: {
    if ($tagsList !== null && $tagsList.tags !== null) {
      const tagObj = $tagsList.tags[params.tagname]
      if (tagObj !== undefined) {
        currentTag.load(tagObj.i)
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      } else {
        navigate('/explore/404', { replace: true })
      }
    }
  }
</script>

<div class="flex flex-column">
  {#if tag != null}
    <Details {data} {tag} />
    <ArtworksSection {data} {tag} />
    <TimeSection {data} />
    <LocationSection {data} />
    <ObjectTypeSection {data} />
    <RelatedTagsSection {data} />
    <BannerSection />
  {/if}
</div>
